import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import { ModalBody, Modal } from "reactstrap";
import "./add-details.css";
import {
  addDemand,
  getAllDemand,
  getAllInventory,
  searchDemandReport,
  getApproveDemand,
  getWarehouseData,
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import DemandReport from "./DemandReport";
import Select from "react-select";
import Swal from "sweetalert";

const AddDemand = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      inventoryCount: "",
      productQuantity: "",
      inventoryLabel: "",
    },
  ]);
  const [demandName, setDemandName] = useState("");
  const [wareHouseId, setwareHouseId] = useState("");
  const [warehouseLabel, setWareHouseLabel] = useState();
  const [orderNumber, setOrderNumber] = useState(0);
  const [demandDescription, setDemandDescription] = useState();
  const [demandType, setDemandType] = useState("");
  const [customerOrder, setCustomerOrder] = useState();
  const [expectedDate, setExpectedDate] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Demand Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeWareHouse = (selectedOption) => {
    setwareHouseId(selectedOption.value);
    setWareHouseLabel(selectedOption.label);
  };

  const getWareHouseList = props.warehouseList.map((x) => {
    let data = { value: x.wareHouseId, label: x.wareHouseName };
    return data;
  });
  const getDemandType = [
    { value: "Local", label: "Local" },
    { value: "Imported", label: "Imported" },
  ];

  const inventory = props.allInventory.map((x) => {
    let data = {
      value: x.inventoryId,
      label:
        x.warehouse.whKey +
        "-" +
        x.category.cateKey +
        "-" +
        x.sub_category.subcateKey +
        "-" +
        x.invKey +
        " " +
        x.productName,
    };
    return data;
  });

  const handleChangeDemandType = (selectedOption) => {
    setDemandType(selectedOption.value);
  };

  function uploadDemand() {
    props.addDemand(
      {
        demandName: demandName,
        demandDescription: demandDescription,
        orderNumber: orderNumber,
        demandType: demandType,
        customerOrder: customerOrder,
        accDate: expectedDate,
        wareHouseId: wareHouseId,
        quantity: inputField,
      },
      handleVisible,
      handleDangerVisible
    );
    setwareHouseId("");
    setWareHouseLabel("");
    setInputField([
      {
        inventoryId: "",
        inventoryCount: "",
        productQuantity: "",
        inventoryLabel: "",
      },
    ]);
    setDemandType("");
    document.getElementById("demandName").value = "";
    document.getElementById("demandDescription").value = "";
    document.getElementById("expextedDate").value = "";
    document.getElementById("productQuantity").value = "";
  }
  const handleChange = (e, index, type) => {
    console.log(e, index, type, "handle");
    let z = find(props.allInventory, (x) => x.inventoryId == e.value);
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") {
      list[index]["inventoryId"] = e.value;
      list[index]["inventoryLabel"] = e.label;
    }
    if (type == "intname")
      list[index]["inventoryCount"] = Number(z.currentBlnc);
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value;
    setInputField(list);
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { inventoryId: "", inventoryCount: "", productQuantity: "" },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  useEffect(() => {
    props.getAllInventory({
      startedDate: "",
      endDate: "",
      wareHouseId: "",
      subCategoryId: "",
      categoryId: "",
    });
    // props.getAllDemand();
    props.getApproveDemand();
    props.getWarehouseData();
  }, []);
  return (
    <>
      {props.isFetchingwareHouseList ||
      props.isFetchingInventoryAll ||
      props.isAddingDemanddata ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingDemanddata ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Row>
                <Col xl="12" lg="12" md="12" sm="12">
                  <Form.Group>
                    <Form.Label>
                      <b> Select WareHouse <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span> </b>
                    </Form.Label>
                    <Select
                      value={{ label: warehouseLabel }}
                      placeholder="Select WareHouse..."
                      onChange={(e) => handleChangeWareHouse(e)}
                      options={getWareHouseList}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Col xl="6" lg="6" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Manual Demand Number <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={demandName}
                    placeholder="Manual Demand Number...."
                    name="demandName"
                    id="demandName"
                    onChange={(e) => setDemandName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Demand Type <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b>
                  </Form.Label>
                  <Select
                    value={{ label: demandType }}
                    placeholder="Select Demand Type..."
                    onChange={(e) => handleChangeDemandType(e)}
                    options={getDemandType}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Production Order Number <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Production Order Number...."
                    value={orderNumber}
                    min={0}
                    name="orderNumber"
                    id="orderNumber"
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xl="6" lg="6" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Customer Order / Customer Reference </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Add Customer Order...."
                    name="customerOrder"
                    id="customerOrder"
                    onBlur={(e) => setCustomerOrder(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Demand Description </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Add Description...."
                    name="demandDescription"
                    id="demandDescription"
                    onChange={(e) => setDemandDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xl="6" lg="6" md="12" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>Expected Completion Date <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b>
                  </Form.Label>

                  <Form.Control
                    id="expextedDate"
                    type="date"
                    value={expectedDate}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) => setExpectedDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
            </Row>
            {inputField.map((item, i) => {
              console.log(item, "item");
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="6" lg="6" md="12" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Inventory <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b>
                        </Form.Label>
                        <Select
                          value={{ label: item.inventoryLabel }}
                          id="inventoryId"
                          placeholder="Select Inventory..."
                          name="inventoryId"
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={inventory}
                          isOptionDisabled={(option) =>
                            !isEmpty(
                              find(
                                inputField,
                                (x) => x.inventoryId == option.value
                              )
                            )
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Available Quantity <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b>
                        </Form.Label>
                        <Form.Control
                          disabled={true}
                          placeholder="Total Quantity"
                          name="inventoryCount"
                          id="inventoryCount"
                          value={item.inventoryCount}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span></b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity...."
                          name="productQuantity"
                          min={0}
                          id="productQuantity"
                          value={item.productQuantity}
                          onChange={(e) => handleChange(e, i, "intquantity")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <IconButton
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "black",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}

                        {inputField.length !== 1 && (
                          <IconButton
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{ float: "right", color: "black" }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <div className="sendDiv">
              {wareHouseId=="" ||
              demandType=="" ||
              expectedDate=="" ||
              orderNumber==0||
              demandName==""||
              inputField[0].inventoryId==""||
              inputField[0].productQuantity=="" ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button className="sendButton" onClick={uploadDemand}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addDemand: (data, handleVisible, handleDangerVisible) =>
      dispatch(addDemand(data, handleVisible, handleDangerVisible)),
    getAllDemand: () => dispatch(getAllDemand()),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    searchDemandReport: (data, name, startDate, endDate) =>
      dispatch(searchDemandReport(data, name, startDate, endDate)),
    getApproveDemand: () => dispatch(getApproveDemand()),
    getWarehouseData: () => dispatch(getWarehouseData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  warehouseList: state.AddDataReducer.warehouseList,
  isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
  allInventory: state.AddDataReducer.allInventory,
  isFetchingDemand: state.AddDataReducer.isFetchingDemand,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  isAddingDemanddata: state.AddDataReducer.isAddingDemanddata,
  notAddDemandData: state.AddDataReducer.notAddDemandData,
  approveDemandList: state.AddDataReducer.approveDemandList,
  isFetchningApproveDemandList:
    state.AddDataReducer.isFetchningApproveDemandList,
  isFetchingSearchDemandList: state.AddDataReducer.isFetchingSearchDemandList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDemand);
