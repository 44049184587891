import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { getOrder, searchOrder } from "../OrderDetail/Order.actions";
import { useEffect } from "react";
import { isEmpty, sumBy } from "lodash";


function OrderExpandView(props) {
    const [search, setSearch] = useState("");
    const [searchTable, setSearchTable] = useState("");

    useEffect(() => {
        props.getOrder();
    }, []);

    return (
        <Container fluid>
            <div >
            </div>
            {props.isFetchingOrder ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingOrder ? "Loading..." : "Saving..."}
                    </Button>
                </div>
            ) : (
                <div className="main" style={{ backgroundColor: "#e9ecef" }}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    placeholder="Search Order"
                                    name="receivedBy"
                                    // onChange={(e) => props.filterItem(e.target.value)}
                                    value={search}
                                    onChange={(e) => {
                                        setSearchTable("")
                                        props.searchOrder(e.target.value);
                                        setSearch(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        {/* <Col xl="6" lg="6" md="6" sm="6" className="mt-2 mb-2">
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    placeholder="Search Order By Table..."
                                    value={searchTable}
                                    name="receivedBy"
                                // onChange={(e) => {
                                //     setSearch("")
                                //     props.searchOrderbyTable(e.target.value);
                                //     setSearchTable(e.target.value)
                                // }}
                                />
                            </Form.Group>
                        </Col> */}
                    </Row>
                    <Row>
                        {isEmpty(props.listOrder) ? (<b style={{ textAlign: "center" }}>No Order Found</b>) : (props.listOrder.map((item, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <Col xl="3" lg="4" md="6" sm="12">
                                        <Card style={{ marginBottom: "5%", borderRadius: "15px", backgroundColor: item.orderStatus == 'Pending' ? "#f6e1e1" : "#d5fdd5" }} >
                                            <CardActionArea>
                                                <CardContent>
                                                    <Typography gutterBottom >
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Customer Name: ${isEmpty(item.customer_table) ? '-----' : item.customer_table.customer_name}`}</p></Col>
                                                        </Row>
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Manual Code: ${isEmpty(item.customer_table) ? '-----' : item.customer_table.customer_cnic}`}</p></Col>
                                                        </Row>
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Order Number: ${item.orderNumber}`}</p></Col>
                                                        </Row>
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Description: ${item.orderDesc}`}</p></Col>
                                                        </Row>
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Order Amount: ${item.orderAmount}`}</p></Col>
                                                        </Row>
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Cash Recived: ${item.cashReceived}`}</p></Col>
                                                        </Row>
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Pending Amount: ${item.pendingAmount}`}</p></Col>
                                                        </Row>
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Total Expense: ${isEmpty(item.expense_form_data) ? 0 : sumBy(item.expense_form_data, 'expense_form_total_price')}`}</p></Col>
                                                        </Row>
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Grand Total: ${Number(item.orderAmount) - Number(isEmpty(item.expense_form_data) ? 0 : sumBy(item.expense_form_data, 'expense_form_total_price'))}`}</p></Col>
                                                        </Row>

                                                        {/* <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Order Type: ${item.orderType}`}</p></Col>
                                                        </Row>
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Date: ${item.orderDate}`}</p></Col>
                                                        </Row>
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Deliver Date: ${item.orderDeliveryDate}`}</p></Col>
                                                        </Row>
                                                        <Row >
                                                            <Col><p style={{ marginBottom: '2px' }}> {`Status: ${item.orderStatus}`}</p></Col>
                                                        </Row> */}

                                                        {item.expense_form_data.map((item1, i1) => {
                                                            return (
                                                                <Card style={{ marginTop: '13px', paddingBottom: "0px" }}>
                                                                    <CardContent style={{ paddingBottom: "14px" }}>
                                                                        <Typography>
                                                                            <React.Fragment>
                                                                                {/* <Row>
                                                                                    <Col><p style={{ marginBottom: '0px' }}> {`Proces: ${item1.expense_form_desc}`}</p></Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col><p style={{ marginBottom: '0px' }}> {`Qty: ${item1.expense_form_quantity}`}</p></Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col><p style={{ marginBottom: '0px' }}> {`Price: ${item1.expense_form_data_price}`}</p></Col>
                                                                                </Row> */}
                                                                                <Row>
                                                                                    <Col><p style={{ marginBottom: '0px' }}> {`${item1.expense_form_desc} (${item1.expense_form_quantity} x ${item1.expense_form_data_price}) = ${item1.expense_form_total_price}`}</p></Col>
                                                                                    {/* <Col><p> {`Proces: ${item1.expense_form_quantity}`}</p></Col> */}
                                                                                    {/* <Col><p> {`Proces: ${item1.expense_form_data_price}`}</p></Col> */}
                                                                                </Row>
                                                                            </React.Fragment>
                                                                        </Typography>
                                                                    </CardContent>
                                                                </Card>
                                                            );
                                                        })}

                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Col>
                                </React.Fragment>
                            );
                        })
                        )}
                    </Row>
                </div>
            )
            }
        </Container >
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        getOrder: () => dispatch(getOrder()),
        searchOrder: (data) => dispatch(searchOrder(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingOrder: state.OrderReducer.isFetchingOrder,
    listOrder: state.OrderReducer.listOrder,
    searchOrderList: state.OrderReducer.searchOrderList,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderExpandView);
