import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import "./add-details.css";
import {
    getCategoryData, addCategoryName, deleteCategory, getWarehouseData, getsubCategoryData, addInventory, getInventory
    , deleteInventory, updateInventory, addDemand, getAllDemand, getAllInventory
} from "./AddDetails.actions";
import { connect } from "react-redux";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import WareHouseModal from "./WareHouseModal";
import MaterialTable from 'material-table';
import {isEmpty} from "lodash";

const DemandReport = (props) => {
    const wareHouseData = props.demandList;
   /* const [state, setState] = React.useState({
        columns: [
            { title: 'Demand Number', field: 'demandId' },
            { title: 'Manual Demand Number', field: 'demandName' },
            { title: 'Production Order Number', field: 'orderNumber', customSort: (a, b) => a - b },
            { title: 'Demand Description',  field: 'demandDescription' },
            { title: 'Demand Type', field: 'demandType' },
            { title: 'Customer Order #', field: 'customerOrder' },
            { title: 'Expected Completion Date', field: 'accDate' },
            { title: 'Creation Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
          
        ],
        columnsData: [
            { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.sub_category.subcateKey + "-" + rowData.inventory.invKey },
            { title: 'Inventory Name', field: 'productName' },
            { title: 'Demand Quantity', field: 'suggestedQuantity' },
            { title: 'Approve Quantity', field: 'approvedQuantity' },
           
        ],
    });*/
    useEffect(() => {
        props.getAllDemand();
    }, []);
    return (
        <>
            {props.isFetchingDemand ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Demand Details"
                            columns={[
                                { title: 'Demand Number', field: 'demandId' },
                                { title: 'Manual Demand Number', field: 'demandName' },
                                // { title: 'Production Order Number', field: 'orderNumber', customSort: (a, b) => a - b },
                                { title: 'Demand Description',  field: 'demandDescription' },
                                { title: 'Demand Type', field: 'demandType' },
                                 { title: 'Status', field: 'status' },
                                { title: 'Expected Completion Date', field: 'accDate' },
                                { title: 'Creation Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
                              
                            ]}
                            data={isEmpty(wareHouseData) ? [] : wareHouseData.reverse()}
                            detailPanel={rowData => {
                                return (
                                    <MaterialTable
                                        title="Product Details"
                                        columns={[
                                            { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.sub_category.subcateKey + "-" + rowData.inventory.invKey },
                                            { title: 'Inventory Name', field: 'productName' },
                                            { title: 'Demand Quantity', field: 'suggestedQuantity' },
                                            { title: 'Approve Quantity', field: 'approvedQuantity' },
                                           
                                        ]}
                                        data={rowData.demand_quantities}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: true,
                                            sorting: true,
                                            paging:true,
                                            pageSize:200,       // make initial page size
                                            emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                            pageSizeOptions:[50,100,150,200], 
                                            headerStyle: {
                                                position: 'sticky', top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}

                                    />
                                )
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                paging:true,
                                pageSize:200,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions:[50,100,150,200], 
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllDemand: () => dispatch(getAllDemand()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    demandList: state.AddDataReducer.demandList,
    isFetchingDemand: state.AddDataReducer.isFetchingDemand,
    isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DemandReport);