import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { getQuotationApi, getInvoicePdf, deleteQuote } from "./QuotationAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const QuotationList = (props) => {
    const history = useHistory();
    useEffect(() => {
        props.getQuotationApi();
    }, []);
    return (
        <>
            {(props.isFetchingQuoteData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <div>
                            <Form>
                                <MaterialTable
                                    title="Invoice Detail"
                                    columns={[
                                        { title: 'Date', field: 'date' },
                                        { title: 'Customer Name', field: 'level_five.levelFiveName' },
                                        { title: 'Description', field: 'description' },
                                        { title: 'Quotation Total', field: 'total_amount' },
                                        { title: 'Quotation Status', field: 'status' },
                                    ]}
                                    data={props.quoteData}
                                    detailPanel={(rowData) => {
                                        return (
                                            <MaterialTable
                                                title="Invoice Detail"
                                                columns={[
                                                    { title: "Description", field: "description" },
                                                    { title: "Uom", field: "uom" },
                                                    { title: "Quantity", field: "quantity" },
                                                    { title: "Price", field: "price" },
                                                    { title: "Total Price", field: "total_value" }
                                                ]}
                                                data={rowData.quotation_data}
                                                options={{
                                                    actionsColumnIndex: -1,
                                                    toolbar: false,
                                                    //   exportButton: true,
                                                    sorting: true,
                                                    paging: false,
                                                    pageSize: 50, // make initial page size
                                                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                                                    pageSizeOptions: [50, 100, 150, 200],
                                                    headerStyle: {
                                                        position: "sticky",
                                                        top: 0,
                                                        color: "#00BBBB",
                                                        fontWeight: "550",
                                                        onRowAdd: "none",
                                                    },
                                                }}
                                            />
                                        );
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        toolbar: false,
                                        //   exportButton: true,
                                        sorting: true,
                                        paging: true,
                                        pageSize: 50, // make initial page size
                                        emptyRowsWhenPaging: false, // To avoid of having empty rows
                                        pageSizeOptions: [50, 100, 150, 200],
                                        headerStyle: {
                                            position: "sticky",
                                            top: 0,
                                            color: "#00BBBB",
                                            fontWeight: "550",
                                            onRowAdd: "none",
                                        },
                                    }}
                                    editable={{
                                        isEditHidden: rowData => true,
                                        isDeleteHidden: rowData => false,
                                        onRowUpdate: (newData, oldData) =>
                                          new Promise((resolve) => {
                                            setTimeout(() => {
                                              resolve();
                                                // props.updateHall(newData, oldData);
                                                // props.getReceivingById(oldData.receiving_id)
                                                // history.push('/user/edit-quotation')
                                            }, 600);
                                          }),
                                        onRowDelete: (oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    props.deleteQuote(oldData.id);
                                                }, 600);
                                            }),
                                    }}
                                    actions={[
                                        (rowData) => {
                                            return isEmpty(rowData)
                                                ? {
                                                    icon: LocalPrintshopIcon,
                                                    disabled: true,
                                                }
                                                : {
                                                    icon: LocalPrintshopIcon,
                                                    disabled: false,
                                                    onClick: (event, rowData) => {
                                                        props.invoicePdf(rowData)
                                                    },
                                                }
                                        },
                                    ]}
                                />
                            </Form>
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        invoicePdf: (data) => dispatch(getInvoicePdf(data)),
        getQuotationApi: () => dispatch(getQuotationApi()),
        deleteQuote: (id) => dispatch(deleteQuote(id)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingQuoteData: state.QuotationReducer.isFetchingQuoteData,
    quoteData: state.QuotationReducer.quoteData,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuotationList);