import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import {
  getLevel5LedgerData,
  searchAccountCashBook,
  searchAccountLedger,
  searchAccountLedgerReport,
} from "../ledger/Ledger.action";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import "../ledger/style.css";
import { isEmpty, get } from "lodash";
import Select from "react-select";
import { Redirect, Link } from "react-router-dom";
import { startFinancialDate } from "../../../../global/globalFunctions";
import moment from "moment";

const AccountCashBook = (props) => {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [item, setItem] = useState("");
  const [itemLabel, setItemLabel] = useState("");
  

  const getLevel5LedgerData1 = props.level5LedgerData.map((x) => {
    let data = {
      value: x.levelFiveId,
      label: x.allLevelKey + "" + x.levelFiveName,
    };
    return data;
  });
  const handleChangeLevel5Ledger = (selectedOption) => {
    setItem(selectedOption.value);
    setItemLabel(selectedOption.label)
  };

  const exportPDF = () => {
    props.searchAccountCashBook(
      {
        startedDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        levelFiveId: item,
      },
      startDate,
      endDate,
      itemLabel
    );
  };

  useEffect(() => {
    props.getLevel5LedgerData();
  }, []);

  function searchData() {
    props.searchAccountLedger({
      startedDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
      levelFiveId: item,
    });
    return <Redirect to="/user/Ledger-List" />;
  }

  return (
    <>
      {props.isFetchingLevel5LedgerData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingLevel5LedgerData ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Account </b>
                  </Form.Label>
                  <Select
                    placeholder="Select Account..."
                    onChange={handleChangeLevel5Ledger}
                    options={getLevel5LedgerData1}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv" style={{ marginTop: "15px" }}>
              {item == "" ? (
                <Button
                  disabled
                  style={{ backgroundColor: "black" }}
                  className="sendButton"
                >
                  Generate Cash Book
                </Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Generate Cash Book
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel5LedgerData: () => dispatch(getLevel5LedgerData()),
    searchAccountLedger: (data) => dispatch(searchAccountLedger(data)),
    searchAccountLedgerReport: (data, startDate, endDate) =>
      dispatch(searchAccountLedgerReport(data, startDate, endDate)),
    searchAccountCashBook: (data, startDate, endDate,itemLabel) =>
      dispatch(searchAccountCashBook(data, startDate, endDate,itemLabel)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  searchAccountLedgerList: state.AccountLegderReducer.searchAccountLedgerList,
  searchAccountCashBookList:
    state.AccountLegderReducer.searchAccountCashBookList,
  isFetchingSearchAccountCashBook:
    state.AccountLegderReducer.isFetchingSearchAccountCashBook,
  isFetchingLevel5LedgerData:
    state.AccountLegderReducer.isFetchingLevel5LedgerData,
  isFetchingSearchAccountLedger:
    state.AccountLegderReducer.isFetchingSearchAccountLedger,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountCashBook);
