import React, { useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { addEvent, getEvent, deleteEvent, UpdateEvent, /*UpdateHREvent */} from './MarqueeDetails.actions';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import jsPDF from "jspdf";
import { isEmpty } from "lodash";
import logo from "../../../../../Images/logo.png";
//import logo from "../../../../Images/logo.png";

const EventList = (props) => {
   
    const eventData = isEmpty(props.eventList) ? [] : props.eventList.reverse();
    console.log(eventData);
    useEffect(() => {
        props.getEvent(2);

    }, []);
    return (
        <>
            

                <Container fluid>
                    {props.isFetchingEventData ? (
                        <div className="loader-div">
                            <Button variant="info" disabled>
                                <Spinner
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="info"
                                />
                                Fetching Event Data
                            </Button>
                        </div>
                    ) : (

                        <MaterialTable
                            title="Event"
                            columns={[
                                { title: 'Event Name', field: 'event_name',render:rowData=>rowData.event_name },
                                { title: 'Event Description', field: 'event_desc',render:rowData=>rowData.event_desc  },
                            ]}
                            data={eventData}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [20,50, 100,150, 200],
                                exportAllData: true,
                                exportPdf: (columns, data) => {
                                    const doc = new jsPDF();
                  
                                    const columnTitles =[
                                        { title: 'Event Name', field: 'event_name',render:rowData=>rowData.event_name },
                                        { title: 'Event Description', field: 'event_desc',render:rowData=>rowData.event_desc  },
                                    ]
                                      .map(columnDef => columnDef.title);
                                      const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                                      const companyAddress=`${process.env.REACT_APP_COMPANY_ADDRESS}`;
                                      const companyNtn=`${process.env.REACT_APP_COMPANY_NTN}`;
                                    const pdfData = data.map((rowData) => [rowData.event_name, rowData.event_desc]);
                                    let content = {
                                      startY: 50,
                                      startX: 5,
                                      head: [columnTitles],
                                      body: pdfData
                                    };
                                    doc.addImage(logo, 'PNG', 10, 1, 50, 50);
                                    doc.setFontSize(20);
                                    doc.setFont(undefined,'bold')
                                    doc.text(companyName, 50, 22, 0, 0);
                                    doc.setFontSize(13);
                                    doc.setFont('TimesNewRoman','Normal')
                                    doc.text(companyAddress,50, 29, 0, 0);
                                    doc.text(companyNtn,50, 36, 0, 0);
                                    doc.autoTable(content);
                                    doc.save(`event Table.pdf`);
                                  },
                  

                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },

                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            {
                                                // console.log('newData', newData),
                                                props.UpdateEvent(newData, oldData)
                                            }
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteEvent(oldData.event_id);
                                            console.log(oldData, 'old')
                                        }, 600);
                                    }),
                            }}
                        />)
                    }


                </Container>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEvent: (id) => dispatch(getEvent(id)),
        UpdateEvent: (newData, oldData) => dispatch(UpdateEvent(newData, oldData)),
        deleteEvent: (event_id) => dispatch(deleteEvent(event_id))

    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingEventData: state.MarqueeReducer.isFetchingEventData,
    eventList: state.MarqueeReducer.eventList
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventList);

