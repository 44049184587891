
import React, { Component } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Button, Nav } from "react-bootstrap";
import {isEmpty, filter} from 'lodash';
//import logo from "../../assets/img/rea"
import logo from "../../../../Images/logo.png";
import { makeStyles } from '@material-ui/core/styles';
import { styled, } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import "../Sidebar/Style.css";

// import production from "../../forms/production/AddSize";
import Level4 from "../../forms/accountsLevels/Level4"

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { isEmptyStatement } from "@babel/types";


const ClickUser = sessionStorage.getItem("clickUser");

const useStyles = makeStyles({
  test: {
  backgroundColor:'transparent',
  color:'black'
  },
  summary:{
    textAlign:"justify",
    justifyContent:"center",
  },
  // bullet: {
  //   display: 'inline-block',
  //   margin: '0 2px',
  //   transform: 'scale(0.8)',
  // },
  // title: {
  //   fontSize: 14,
  // },
  // pos: {
  //   marginBottom: 20,
  // },
  // color: {
  //   color: "#FFFFFF"
  // }
});


function Sidebar({ color, image, routes }) {

  console.log(routes, "ppuu")
 
  const location = useLocation();
  const [expanded, setExpanded] = React.useState('');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const classes = useStyles()
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
     // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'transparent'
        : '#fff',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const [open, setOpen] = React.useState(true);
  const [name, setName] = React.useState("");

  const handleClick = () => {
    setOpen(!open);
  };
  
  const handleAnchorClick = (event, message) => {
    // 👇️ use event.preventDefault() if you want to
    // prevent navigation
    // event.preventDefault();

    console.log('Anchor element clicked');
    console.log(message);
    setName(message)
  };
  const activeRoute = (routeName) => {
    
    return location.pathname.indexOf(routeName) > -1 ? "active" : "",
    console.log(location.pathname.indexOf(routeName), "ttyy")
  };
  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          {/* <a
            href="https://www.creative-tim.com?ref=lbd-sidebar"
            className="simple-text logo-mini mx-1"
          >
            <div className="logo-img">
            {/*  <img
                src={require("../../assets/img/l").default}
                alt="..."
            />*
            </div>
          </a> */}
          {/* <a className="simple-text" href="http://www.creative-tim.com">
ERP System
          </a> */}
          { <img src={logo} style={{marginLeft:30}} alt = "logo" width={'115px'}/> }{//chag kiya
          }
        </div>
        {/* <div>
          <p>Master</p>
        </div> */}
        
        
         {routes.find(x=> x.name == "Add Division") &&
         <div >
          {/* <hr/> */}
          <p style={{marginLeft:'5px',color:'gray',marginBottom:'-2px'}}><b>Master Setting</b></p>
          <hr style={{color:'white',backgroundColor:'white',marginTop:'-2px'}}/>
         </div>}
    
         <Nav>
           
          {filter(routes,x => x.show).map((prop, key) =>  {
          console.log(routes,prop,key, "ppoo")
            
            if (!prop.redirect)
            return isEmpty(prop.children) ?
         
               (console.log("IsEmpty"),
               <li
                  className={
                    prop.upgrade
                      ? "active active-pro"
                      : activeRoute(prop.layout + prop.path)
                  }
                  key={key}
                >
                  {console.log(prop,routes,"testcolor")}
                  <NavLink
                  
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                    onClick={event =>
                      handleAnchorClick(event, prop.name)
                    }
                    style={{backgroundColor:prop.name==name?"#757474":""}}
                    // style={({ isActive, isPending, isTransitioning }) => {
                    //   console.log(isActive,"isActive")
                    //   return {
                    //     fontWeight: isActive ? "bold" : "",
                    //     color: isPending ? "red" : "black",
                    //     viewTransitionName: isTransitioning ? "slide" : "",
                    //   };
                    // }}
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                    {/* <br/> */}
                    {/* {routes.find(x=> x.name == "Add Division")&&key==6&&
                    <hr style={{color:'white',height:'5px',backgroundColor:'white'}}/>
                  }  */}
                  </NavLink>
                  {routes.find(x=> x.name == "Add Division")&&key==6&&
                  <div>
                    <hr style={{color:'white',backgroundColor:'white',marginBottom:'-2px'}}/>
                    <p style={{marginLeft:'5px',color:'gray',marginBottom:'-2px'}}><b>Employee Mangment</b></p>
                    <hr style={{color:'white',backgroundColor:'white',marginTop:'-2px'}}/>
                    </div>
                    }
                        {routes.find(x=> x.name == "Add Division")&&key==14&&
                  <div>
                    <hr style={{color:'white',backgroundColor:'white',marginBottom:'-2px'}}/>
                    <p style={{marginLeft:'5px',color:'gray',marginBottom:'-2px'}}><b>Salary Creation</b></p>
                    <hr style={{color:'white',backgroundColor:'white',marginTop:'-2px'}}/>
                    </div>
                    }
                </li>
                // (key==6?<div>aa</div>:"")
                
                )
                : 
                
                (console.log("NotIsEmpty"),
                   <Accordion className={classes.test}  expanded={expanded === 'panel1'} onChange={handleChange('panel1')} 
                   style={{backgroundColor:"transparent"}}>
                     <AccordionSummary  className={classes.summary} aria-controls="panel1d-content" id="panel1d-header"
                     style={{backgroundColor:"transparent",color:"white"}}>
                       <Typography ><strong>{prop.name}</strong></Typography>
                     </AccordionSummary>
                     <AccordionDetails style={{padding:"0px"}}>
                       <Nav>
                         
                       {filter(prop.children, x => x.show).map((prop, key) =>  {
                         if (!prop.redirect)
                           return (
                             <li
                               className={
                                 prop.upgrade
                                   ? "active active-pro"
                                   : activeRoute(prop.layout + prop.path)
                               }
                               key={key}
                             >
                               <NavLink
                                 to={prop.layout + prop.path}
                                 className="nav-link"
                                 activeClassName="active"
                               >
                                 <i className={prop.icon} />
                                 <p>{prop.name}</p>
                               </NavLink>
                             </li>
                             
                             
                           );
                         return null;
                       })}
                       </Nav>
                     </AccordionDetails>
                   </Accordion>
                )
                
                

            return null;
          })}
        
        </Nav>

{/* } */}

{/* {routes.find(x=> x.name == "Production") &&  */}
          <div >
            {/* <List
      sx={{ width: '100%' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Production" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>

        <List component="div" disablePadding>

          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Add" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Add Size" />
          </ListItemButton>
        </List>
      </Collapse>
    </List> */}

      {/* <Accordion className={classes.test}  expanded={expanded === 'panel1'} onChange={handleChange('panel1')} 
      style={{backgroundColor:"transparent"}}>
        <AccordionSummary  className={classes.summary} aria-controls="panel1d-content" id="panel1d-header"
        style={{backgroundColor:"transparent",color:"white"}}>
          <Typography ><strong>Production</strong></Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding:"0px"}}>
          <Nav>
          {filter(routes,x => x.show).map((prop, key) =>  {
            if (!prop.redirect)
              return (
                <li
                  className={
                    prop.upgrade
                      ? "active active-pro"
                      : activeRoute(prop.layout + prop.path)
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
                
                
              );
            return null;
          })}
          </Nav>
        </AccordionDetails>
      </Accordion> */}
      
    </div>
{/* } */}
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

const mapStateToProps = (state, ownProps, number) => ({
 moduleKey: state.accountReducer.moduleKey,
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
