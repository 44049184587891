import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import AddExpense from "./AddExpense";
// import ExpenseList from "./ExpenseList";
// import ReportMarquee from "./ReportMarquee";

const TotalExpenseData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    return (
        <>
            <Tabs>
                <Tab eventKey="AddExpense" title="Add Expense">
                    <AddExpense />
                </Tab>
                {/* <Tab eventKey="ExpenseList" title="Expense List" >
                    <ExpenseList />
                </Tab> */}
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => { return {}; };
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalExpenseData);