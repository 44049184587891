import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import { addExpense, getExpense, deleteExpense, getCustomerMarquee, UpdateExpence, } from "./MarqueeDetails.actions";
import { getOrder, getOrderWithCustomerId } from "../../OrderDetail/Order.actions"
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find, filter } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";


const AddExpense = (props) => {
  // const [orderId, setOrderId] = useState("");
  // const [customer_id, setCustomerId] = useState();
  const [expenseDescription, setExpenseDescription] = useState();
  const [expenseQuantity, setExpenseQuantity] = useState();
  const [expenseTotalPrice, setExpenseTotalPrice] = useState();
  const [expensePrice, setExpensePrice] = useState();
  const [submit, setSubmit] = useState(false);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [bookingName, setBookingName] = useState("");
  const [customerName, setCustomerName] = useState();
  const [customerNameValue, setCustomerNameValue] = useState();
  const [customerNameLabel, setCustomerNameLabel] = useState();
  const [orderValue, setOrderValue] = useState();
  const [orderLabel, setOrderLabel] = useState();


  const handleAddInput = () => {
    setInputField([...inputField,
    {
      // master_control_item_id: "",
      expense_form_data_price: 0,
      expense_form_desc: "",
      expense_form_quantity: 0,
      expense_form_total_price: 0,
    },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  const handleChange = (e, index, type) => {
    // let z = find(props.allInventory, (x) => x.master_control_item_id == e.value);
    // const { name, value } = e;
    const list = [...inputField];
    // if (type == "intname") list[index]["master_control_item_id"] = e.value;
    // if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "expense_form_desc") list[index]["expense_form_desc"] = e.target.value;
    if (type == "expense_form_data_price") { list[index]["expense_form_data_price"] = Number(e.target.value); }
    if (type == "expense_form_quantity") { list[index]["expense_form_quantity"] = Number(e.target.value); }
    if (type == 'expense_form_data_price' || type == 'expense_form_quantity') list[index]['expense_form_total_price'] = (list[index]['expense_form_data_price'] * list[index]['expense_form_quantity']);

    setInputField(list);
  };

  const [inputField, setInputField] = useState([
    {
      // master_control_item_id: "",
      expense_form_data_price: 0,
      expense_form_desc: "",
      expense_form_quantity: 0,
      expense_form_total_price: 0,
    },
  ]);

  const clearStates = () => {
    // setExpenseName();
    setExpenseDescription();
    setSubmit(false);
  };

  useEffect(() => {
    props.getExpense();
    props.getOrder();
  }, []);


  function uploadExpense() {
    props.addExpense({
      orderId: orderValue,
      customer_id: customerName,
      // expense_desc: expenseDescription,
      data: inputField,
    },
      handleVisible,
      handleDangerVisible
    );
    setInputField([{
      expense_form_data_price: 0,
      expense_form_desc: "",
      expense_form_quantity: 0,
      expense_form_total_price: 0,
    }]);
    setOrderLabel("")
    setCustomerNameLabel("")
  }
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something Went Wrong",
      icon: "error",
      button: "Ok",
    });
  };
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Expense Data Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleChangeCustomer = (selectedOption) => {
    setCustomerName(selectedOption.value);
    setCustomerNameLabel(selectedOption.label);
    setCustomerNameValue(selectedOption.value);
    props.getOrderWithCustomerId(selectedOption.value);
  };
  const getCustomerList = props.customerMarqueeList.map((x) => {
    let data = { value: x.customer_id, label: `${x.customer_cnic} / ${x.customer_name} ` };
    return data;
  });

  const handleChangeGetOrder = (selectedOption) => {
    setOrderValue(selectedOption.value);
    setOrderLabel(selectedOption.label);
    // props.getOrderWithCustomerId({ purchaseOrderId: selectedOption.value });
  };
  const getListOrderWithCustomerId = !isEmpty(props.listOrderWithCustomerId) && props.listOrderWithCustomerId.map((x) => {
    let data = {
      value: x.orderId,
      label: x.orderNumber,
    };
    return data;
  });

  return (
    <>
      {props.isFetchingCustomerDataMarquee || props.isAddingExpenseData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b> Select Customer *</b>
                    </Form.Label>
                    <Select
                      value={{ label: customerNameLabel }}
                      isDisabled={isEmpty(getCustomerList)}
                      placeholder="Select Customer"
                      onChange={(e) => handleChangeCustomer(e)}
                      options={isEmpty(getCustomerList) ? [] : getCustomerList}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Select Order *</b>
                    </Form.Label>
                    <Select
                      // isDisabled={isEmpty(getOrderList)}
                      // placeholder="Select Order"
                      // onChange={setOrderId}
                      // options={isEmpty(getOrderList) ? [] : getOrderList}

                      value={{ label: orderLabel }}
                      isDisabled={isEmpty(getListOrderWithCustomerId)}
                      placeholder="Select Order"
                      onChange={(e) => handleChangeGetOrder(e)}
                      options={isEmpty(getListOrderWithCustomerId) ? [] : getListOrderWithCustomerId.reverse()}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {inputField.map((item, i) => {
                console.log(item, "sssssssssssssssssss")
                return (
                  <React.Fragment key={i}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Process *</b>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            rows={1}
                            placeholder="Process"
                            onChange={(e) => handleChange(e, i, "expense_form_desc")}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Per Unit Cost *</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            rows={1}
                            placeholder="Per Unit Cost"
                            onChange={(e) => handleChange(e, i, "expense_form_data_price")}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Quantity *</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Quantity"
                            onChange={(e) => handleChange(e, i, "expense_form_quantity")}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Total *</b>
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="number"
                            placeholder="Total"
                            value={(Math.round(item.expense_form_total_price * 100) / 100).toFixed(2)}
                          // onChange={(e) => handleChange(e, i, "totalPrice")}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Row>
                        <Col lg="12">
                          {inputField.length - 1 == i && (
                            <IconButton
                              title="Click to Add"
                              onClick={handleAddInput}
                              style={{
                                float: "right",
                                color: "black",
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          )}

                          {inputField.length !== 1 && (
                            <IconButton
                              title="Click to Remove"
                              onClick={() => handleRemoveInput(i)}
                              style={{ float: "right", color: "black" }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                  </React.Fragment>
                );
              })}

              <div className="sendDiv">
                {
                  <Button className="sendButton" onClick={() => { uploadExpense(); setSubmit(true); }}> Save </Button>
                }
              </div>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderWithCustomerId: (id) => dispatch(getOrderWithCustomerId(id)),
    getCustomerMarquee: () => dispatch(getCustomerMarquee()),
    addExpense: (data, handleVisible, handleDangerVisible) => dispatch(addExpense(data, handleVisible, handleDangerVisible)),
    getExpense: () => dispatch(getExpense()),
    UpdateExpense: (newData, oldData) => dispatch(UpdateExpence(newData, oldData)),
    deleteExpense: (id) => dispatch(deleteExpense(id)),
    getOrder: () => dispatch(getOrder()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
  isFetchingCustomerDataMarquee: state.MarqueeReducer.isFetchingCustomerDataMarquee,
  departmentList: state.MarqueeReducer.departmentList,
  isFetchingExpenseData: state.MarqueeReducer.isFetchingExpenseData,
  isAddingExpenseData: state.MarqueeReducer.isAddingExpenseData,
  expenseList: state.MarqueeReducer.expenseList,
  isFetchingOrder: state.MarqueeReducer.isFetchingOrder,
  listOrder: state.MarqueeReducer.listOrder,
  listOrderWithCustomerId: state.OrderReducer.listOrderWithCustomerId,
  // isFetchingOrderWithCustomerId: state.OrderReducer.isFetchingOrderWithCustomerId,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddExpense);
