import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import { getOrder } from "./Order.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect, useRef } from "react";
import Swal from "sweetalert";
import { isEmpty, find, map, filter } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import logo from "../../../../Images/logo.png";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import TaskIcon from '@mui/icons-material/Task';


const ListCompleteOrder = (props) => {
    const OrderData = isEmpty(props.listOrder) ? [] : props.listOrder;
    const completeOrder = filter(OrderData, { orderStatus: 'Completed', });

    useEffect(() => {
        props.getOrder();
    }, []);
    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };

    return (
        <>
            <Container fluid>
                {props.isFetchingOrder ? (
                    <div className="loader-div">
                        <Button variant="info" disabled>
                            <Spinner
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="info"
                            />
                            Fetching Order Data
                        </Button>
                    </div>
                ) : (
                    <MaterialTable
                        style={{ zIndex: 0 }}
                        title="Shipped Orders"
                        columns={[
                            { title: 'Order ID', field: 'orderId' },
                            { title: 'Customer Code', field: `customer_table.customer_cnic` },
                            { title: 'Order Status', field: 'orderStatus' },
                            { title: 'Manual Order Number', field: 'orderNumber' },
                            { title: 'Order Description', field: 'orderDesc' },
                            { title: 'Order Date', field: 'orderDate' },
                            { title: 'Order Delivery Date', field: 'orderDeliveryDate' },
                            { title: 'Order Duration', field: 'dayDifference' },
                            { title: 'Order Amount', field: 'orderAmount' },
                            { title: 'Cash Received', field: 'cashReceived' },
                            { title: 'Pending Amount', field: 'pendingAmount' },
                            { title: 'Currency', field: 'currency_symbol' },
                            { title: 'Tracking Id', field: 'trackingId', },
                            { title: 'Courier Company', field: 'courierCompany', },
                        ]}
                        data={completeOrder}

                        detailPanel={(rowData) => {
                            return (
                                <MaterialTable
                                    // title="Expense Items"
                                    columns={[
                                        { title: 'Expense Description', field: 'expense_form_desc' },
                                        { title: 'Expense Quantity', field: 'expense_form_quantity' },
                                        { title: 'Expense Price', field: 'expense_form_data_price' },
                                    ]}
                                    data={rowData.expense_form_data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        toolbar: false,
                                        exportButton: false,
                                        sorting: false,
                                        paging: false,
                                        pageSize: 200,
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [50, 100, 150, 200],
                                        headerStyle: {
                                            position: "sticky",
                                            top: 0,
                                            color: "#00BBBB",
                                            fontWeight: "550",
                                            onRowAdd: "none",
                                        },
                                    }}
                                // editable={{
                                //     onRowUpdate: (newData, oldData) =>
                                //         new Promise((resolve) => {
                                //             setTimeout(() => {
                                //                 resolve();
                                //                 {
                                //                     // console.log('newData', newData),
                                //                     // props.UpdateHRMenu(newData, oldData)
                                //                 }
                                //             }, 600);
                                //         }),
                                //     onRowDelete: (oldData) =>
                                //         new Promise((resolve) => {
                                //             setTimeout(() => {
                                //                 resolve();
                                //                 props.deleteMenu(oldData.menu_id);
                                //                 console.log(oldData, 'old')
                                //             }, 600);
                                //         }),
                                // }}
                                />
                            )
                        }}
                        options={{
                            actionsColumnIndex: -1,
                            filtering: true,
                            exportButton: true,
                            paging: true,
                            pageSize: 50,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [50, 100, 150, 200],
                            headerStyle: {
                                position: 'sticky', top: 0,
                                color: '#00BBBB',
                                fontWeight: '550',
                                onRowAdd: 'none',
                            },
                            exportPdf: (columns, data) => {
                                const doc = new jsPDF();
                                //render:rowData=>rowData.hall_name
                                const columnTitles = [
                                    { title: 'Order ID', field: 'orderId', render: rowData => rowData.orderId },
                                    { title: 'Order Name', field: 'orderType ', render: rowData => rowData.orderType },
                                    { title: 'Manual Order Number', field: 'orderNumber', render: rowData => rowData.orderNumber },
                                    { title: 'Order Description', field: 'orderDesc', render: rowData => rowData.orderDesc },
                                    { title: 'Order Date', field: 'orderDate', render: rowData => rowData.orderDate },
                                    { title: 'Order Amount', field: 'orderAmount', render: rowData => rowData.orderAmount },
                                    { title: 'Cash Received', field: 'cashReceived', render: rowData => rowData.cashReceived },
                                    { title: 'Pending Amount', field: 'pendingAmount', render: rowData => rowData.pendingAmount },
                                    { title: 'Tracking Id', field: 'trackingId', render: rowData => rowData.trackingId },
                                    { title: 'Courier Company', field: 'courierCompany', render: rowData => rowData.courierCompany },
                                    { title: 'Currency', field: 'currency_symbol', render: rowData => rowData.currency_symbol },
                                ]
                                    .map(columnDef => columnDef.title);
                                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                                const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                                const pdfData = data.map((rowData) => [rowData.orderId, rowData.orderType, rowData.orderNumber, rowData.orderDesc, rowData.orderDate, rowData.orderAmount, rowData.cashReceived, rowData.pendingAmount]);
                                let content = {
                                    startY: 50,
                                    startX: 5,
                                    head: [columnTitles],
                                    body: pdfData
                                };
                                doc.addImage(logo, 'PNG', 10, 1, 50, 50);
                                doc.setFontSize(20);
                                doc.setFont(undefined, 'bold')
                                doc.text(companyName, 50, 22, 0, 0);
                                doc.setFontSize(13);
                                doc.setFont('TimesNewRoman', 'Normal')
                                doc.text(companyAddress, 50, 29, 0, 0);
                                doc.text(companyNtn, 50, 36, 0, 0);
                                doc.autoTable(content);
                                doc.save(`Order.pdf`);
                            },
                            headerStyle: {
                                position: 'sticky', top: 0,
                                color: '#00BBBB',
                                fontWeight: '550',
                                onRowAdd: 'none',
                            },

                        }}
                        actions={[
                            (rowData) => {
                                return rowData.orderStatus !== "Pending"
                                    ? {
                                        icon: TaskIcon,
                                        disabled: true,
                                    }
                                    : {
                                        icon: TaskIcon,
                                        disabled: false,
                                        //   onClick: (event, rowData) => {
                                        //     props.updateVoucherStatus(rowData, updateTableQuery);
                                        //   },
                                    };
                            },
                        ]}
                    // editable={{
                    //     onRowUpdate: (newData, oldData) =>
                    //         new Promise((resolve) => {
                    //             setTimeout(() => {
                    //                 resolve();
                    //                 {
                    //                     props.UpdateCustomer(newData, oldData)
                    //                 }
                    //             }, 600);
                    //         }),
                    //     onRowDelete: (oldData) =>
                    //         new Promise((resolve) => {
                    //             setTimeout(() => {
                    //                 resolve();
                    //                 props.deleteCustomer(oldData.customer_id);
                    //                 console.log(oldData, 'old')
                    //             }, 600);
                    //         }),
                    // }}
                    />
                )}
            </Container>
        </>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrder: () => dispatch(getOrder()),
        // deleteCustomer: (id) => dispatch(deleteCustomer(id)),
        // UpdateCustomer: (newData, oldData) => dispatch(UpdateCustomer(newData, oldData)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingOrder: state.OrderReducer.isFetchingOrder,
    listOrder: state.OrderReducer.listOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListCompleteOrder);
