import API from "../../../../global/api";
import logo from "../../../../Images/logo.png";
import { isEmpty, sumBy } from "lodash";
import jsPDF from "jspdf";
import moment from "moment";


export const requestAddOrder = () => {
    return {
        type: "REQUEST_ADD_ORDER",
    };
};
export const successAddOrder = (data) => {
    return {
        type: "SUCCESS_ADD_ORDER",
        payload: data,
    };
};
export const errorAddOrder = () => {
    return {
        type: "ERROR_ADD_ORDER",
    };
};
export const addOrder = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddOrder());
        API.post(`/order/add_order`, data)
            .then((res) => {
                dispatch(getOrder());
                dispatch(successAddOrder(data));
                handleVisible(res.data.message);
            })
            .catch((error) => {
                dispatch(errorAddOrder());
                handleDangerVisible(error.response.data.message);
            });
    };
};


export const requestGetCustomerMarquee = () => {
    return {
        type: "REQUEST_GET_CUSTOMER_MARQUEE",
    };
};
export const successGetCustomerMarquee = (data) => {
    return {
        type: "SUCCESS_GET_CUSTOMER_MARQUEE",
        payload: data,
    };
};
export const errorGetCustomerMarquee = () => {
    return {
        type: "ERROR_GET_CUSTOMER_MARQUEE",
    };
}
export const getCustomerMarquee = () => {
    return (dispatch) => {
        dispatch(requestGetCustomerMarquee());
        API.get(`/customer/show_customer`).then((res) => {
            let getData = res.data;
            dispatch(successGetCustomerMarquee(getData));
        }).catch((error) => {
            dispatch(errorGetCustomerMarquee());
        })
    };
};


export const requestGetOrder = () => {
    return {
        type: "REQUEST_GET_ORDER",
    };
};
export const successGetOrder = (data) => {
    return {
        type: "SUCCESS_GET_ORDER",
        payload: data,
    };
};
export const errorGetOrder = () => {
    return {
        type: "ERROR_GET_ORDER",
    };
}
export const getOrder = () => {
    return (dispatch) => {
        dispatch(requestGetOrder());
        API.get(`/order/get_order`).then((res) => {
            let getData = res.data;
            dispatch(successGetOrder(getData));
        }).catch((error) => {
            dispatch(errorGetOrder());
        })
    };
};


export const requestGetOrderWithCustomerId = () => {
    return {
        type: "REQUEST_GET_ORDER_WITH_CUSTOMER_ID",
    };
};
export const successGetOrderWithCustomerId = (data) => {
    return {
        type: "SUCCESS_GET_ORDER_WITH_CUSTOMER_ID",
        payload: data,
    };
};
export const errorGetOrderWithCustomerId = () => {
    return {
        type: "ERROR_GET_ORDER_WITH_CUSTOMER_ID",
    };
}
export const getOrderWithCustomerId = (customer_id) => {
    return (dispatch) => {
        dispatch(requestGetOrderWithCustomerId());
        API.get(`/order/get_order_with_customerId/${customer_id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetOrderWithCustomerId(getData));
        }).catch((error) => {
            dispatch(errorGetOrderWithCustomerId());
        })
    };
};


export const requestStatusUpdateOrder = () => {
    return {
        type: "REQUEST_STATUS_UPDATE_ORDER",
    };
};
export const successStatusUpdateOrder = (data) => {
    return {
        type: "SUCCESS_STATUS_UPDATE_ORDER",
        payload: data,
    };
};
export const errorStatusUpdateOrder = () => {
    return {
        type: "ERROR_STATUS_UPDATE_ORDER",
    };
};
export const updateOrderStatus = (data, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestAddOrder());
        API.post(`/order/update_order_status`, data).then((res) => {
            dispatch(getOrder());
            dispatch(successStatusUpdateOrder(data));
        }).catch((error) => {
            dispatch(errorStatusUpdateOrder());
        });
    };
};


export const requestGetOrderWithOrderId = () => {
    return {
        type: "REQUEST_GET_ORDER_WITH_ORDERID",
    };
};
export const successGetOrderWithOrderId = (data) => {
    return {
        type: "SUCCESS_GET_ORDER_WITH_ORDERID",
        payload: data,
    };
};
export const errorGetOrderWithOrderId = () => {
    return {
        type: "ERROR_GET_ORDER_WITH_ORDERID",
    };
}
export const getOrderWithOrderIdApi = (orderId) => {
    return (dispatch) => {
        dispatch(requestGetOrderWithOrderId());
        API.get(`/order/get_order_with_orderId/${orderId}`).then((res) => {
            let getData = res.data;
            dispatch(successGetOrderWithOrderId(getData));
        }).catch((error) => {
            dispatch(errorGetOrderWithOrderId());
        })
    };
};


export const requestAddOrderPayment = () => {
    return {
        type: "REQUEST_ADD_ORDER_PAYMENT",
    };
};
export const successAddOrderPayment = (data) => {
    return {
        type: "SUCCESS_ADD_ORDER_PAYMENT",
        payload: data,
    };
};
export const errorAddOrderPayment = () => {
    return {
        type: "ERROR_ADD_ORDER_PAYMENT",
    };
};
export const addOrderPaymentApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddOrderPayment());
        API.post(`/order/receive_pending_payment`, data).then((res) => {
            dispatch(getOrder());
            dispatch(successAddOrderPayment(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddOrderPayment());
            handleDangerVisible(error.response.data.message);
        });
    };
};

export const searchOrder = (data) => {
    return {
        type: "FILTER_ORDER",
        payload: data
    };
};


export const requestOrderPrint = () => {
    return {
        type: "REQUEST_ORDER_PRINT",
    };
};
export const successOrderPrint = (data) => {
    return {
        type: "SUCCESS_ORDER_PRINT",
        payload: data,
    };
};
export const errorOrderPrint = () => {
    return {
        type: "ERROR_ORDER_PRINT",
    };
};
export const getOrderPrintApi = (orderId) => {
    return (dispatch) => {
        dispatch(requestOrderPrint());
        API.get(`/order/get_order_with_orderId/${orderId}`).then((res) => {
            let getData = res.data.data;
            const unit = "pt";
            const size = "A4";
            const orientation = "potrait";
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(11);
            const title = "Order Print";
            const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
            const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
            const customerName = !isEmpty(getData[0].customer_table) ? "Customer Name: " + getData[0].customer_table.customer_name : "Customer Name: - ";
            const customerManualCode = !isEmpty(getData[0].customer_table) ? "Customer Code: " + getData[0].customer_table.customer_cnic : "Customer Code: - ";
            const orderDate = "Order Date: " + getData[0].orderDate;
            const orderNumber = "Order Number: " + getData[0].orderNumber;
            const description = "Description: " + getData[0].orderDesc;
            const orderAmount = "Order Total Amount: " + getData[0].orderAmount;
            const cashRecived = "Cash Received: " + getData[0].cashReceived;
            const pendingAmount = "Pending Amount: " + getData[0].pendingAmount;
            // const totalExpense = "Total Expense: " + sumBy(getData.expense_form_data, 'expense_form_total_price');
            const totalExpense = !isEmpty(getData[0].expense_form_data) ? "Total Expense: " + sumBy(getData[0].expense_form_data, 'expense_form_total_price') : "Total Expense: 0 ";
            const Currency = "Currency: " + getData[0].currency_symbol;

            const headers = [
                [
                    "Serial No",
                    "Process",
                    "Quantity",
                    "Per Unit Cost",
                    "Total Cost",
                ],
            ];
            const data1 = getData[0].expense_form_data.map((elt, index) => [
                index + 1,
                elt.expense_form_desc,
                elt.expense_form_quantity,
                elt.expense_form_data_price,
                elt.expense_form_total_price,
            ]);
            let content = {
                startY: 360,
                startX: 10,
                head: headers,
                // tableWidth: "wrap",
                body: data1,
            };
            doc.addImage(logo, "PNG", 40, 15, 100, 100);
            doc.text(companyName, 150, 40, 0, 20);
            doc.setFontSize(13);
            doc.setFont("times", "normal");
            doc.text(companyAddress, 150, 60, 0, 20);
            doc.text(companyNtn, 150, 80, 0, 20);
            doc.setFontSize(14);
            doc.setFont("times", "bold");
            doc.text(title, 240, 120);
            doc.setFont("times", "normal");
            doc.text(customerName, 50, 150, 0, 20);
            doc.text(customerManualCode, 50, 170, 0, 20);
            doc.text(orderDate, 50, 190, 0, 20);
            doc.text(orderNumber, 50, 210, 0, 20);
            doc.text(description, 50, 230, 0, 20);
            doc.text(orderAmount, 50, 250, 0, 20);
            doc.text(cashRecived, 50, 270, 0, 20);
            doc.text(pendingAmount, 50, 290, 0, 20);
            doc.text(totalExpense, 50, 310, 0, 20);
            doc.text(Currency, 50, 330, 0, 20);
            doc.autoTable(content);
            let finalY = doc.lastAutoTable.finalY;
            doc.setProperties({ title: `Order Report ${moment(new Date()).format("YYYY-MM-DD")}` });
            var blob = doc.output("blob");
            window.open(URL.createObjectURL(blob));
            dispatch(successOrderPrint(getData));

        }).catch((error) => {
            dispatch(errorOrderPrint());
        });
    };
};


export const UpdateRequestOrder = () => {
    return {
        type: "UPDATE_REQUEST_ORDER",
    };
};
export const UpdateSuccessOrder = (data) => {
    return {
        type: "UPDATE_SUCCESS_ORDER",
        payload: data,
    };
};
export const UpdateErrorOrder = () => {
    return {
        type: "UPDATE_ERROR_ORDER",
    };
};
export const updateOrderAPI = (data, oldData) => {
    return (dispatch) => {
        dispatch(UpdateRequestOrder());
        API.put(`/order/update_order/${oldData.orderId}`,
            {
                orderDesc: data.orderDesc,
                orderAmount: data.orderAmount,
                pendingAmount: data.pendingAmount,
                cashReceived: data.cashReceived,
                courierCompany: data.courierCompany,
                trackingId: data.trackingId,
                orderNumber: data.orderNumber,
                currency_symbol: data.currency_symbol,
            },
        ).then((res) => {
            dispatch(getOrder());
            dispatch(UpdateSuccessOrder([data]));
        }).catch((error) => dispatch(UpdateErrorOrder()));
    };
};