const initialState = {
    isFetchingGetCustomer: false,
    customerInfo: [],
    isSavingInvoice: false,
    addInvoice: [],
    isFetchingQuoteData: false,
    quoteData: [],
};


const QuotationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_GET_CUSTOMER":
            return { ...state, isFetchingGetCustomer: true };
        case "SUCCESS_GET_CUSTOMER":
            return { ...state, isFetchingGetCustomer: false, customerInfo: action.payload.result };
        case "ERROR_GET_CUSTOMER":
            return { ...state, isFetchingGetCustomer: false };


        case "REQUEST_ADD_INVOICE":
            return { ...state, isSavingInvoice: true };
        case "SUCCESS_ADD_INVOICE":
            return { ...state, addInvoice: action.payload.data, isSavingInvoice: false, };
        case "ERROR_ADD_INVOICE":
            return { ...state, isSavingInvoice: false };


        case "REQUEST_GET_QUOTE":
            return { ...state, isFetchingQuoteData: true };
        case "SUCCESS_GET_QUOTE":
            return { ...state, isFetchingQuoteData: false, quoteData: action.payload.result };
        case "ERROR_GET_QUOTE":
            return { ...state, isFetchingQuoteData: false };


        default:
            return state;
    }
};
export default QuotationReducer;
