import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { addAging, agingReport } from "./Aging.actions";
import { connect } from "react-redux";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { isEmpty, isDate } from "lodash";
import Select from "react-select";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../../Images/logo.png";
import { startFinancialDate } from "../../../../global/globalFunctions";
import { getLevel5LedgerData } from "../ledger/Ledger.action";
const Aging = (props) => {
  const [item, setItem] = useState("");
  const [itemName, setItemName] = useState("");
  const getLevel5LedgerData1 = props.level5LedgerData.map((x) => {
    let data = {
      value: x.levelFiveId,
      label: x.allLevelKey + " " + x.levelFiveName,
    };
    return data;
  });
  const handleChangeLevel5Ledger = (selectedOption) => {
    setItem(selectedOption.value);
    setItemName(selectedOption.label);
  };
  useEffect(() => {
    props.getLevel5LedgerData();
  }, []);

  function searchData() {
    props.agingReport(
      {
        levelFiveId: item,
      },
      item,
      itemName
    );
  }
  return (
    <>
      <Container fluid>
        <div className="main">
          <div>
            <Form>
              <Row>
                {" "}
                <Col lg="12" md="12" sm="12">
                  <Form.Group>
                    <Form.Label>
                      <b> Select Account </b>
                    </Form.Label>
                    <Select
                      placeholder="Select Account..."
                      onChange={handleChangeLevel5Ledger}
                      options={getLevel5LedgerData1}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                  lg="12"
                  sm="12"
                  className="text-center"
                  style={{ marginTop: "15px" }}
                >
                  {isEmpty(itemName) ? (
                    <Button
                      className="sendButton"
                      disabled
                      style={{ backgroundColor: "black" }}
                      onClick={(e) => searchData()}
                    >
                      Generate Aging Report
                    </Button>
                  ) : (
                    <Button
                      className="sendButton"
                      onClick={(e) => searchData()}
                    >
                      Generate Aging Report
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    agingReport: (data, code, title) =>
      dispatch(agingReport(data, code, title)),
    getLevel5LedgerData: () => dispatch(getLevel5LedgerData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  isFetchingLevel5LedgerData:
    state.AccountLegderReducer.isFetchingLevel5LedgerData,
  agingList: state.AddAgingReducer.agingList,
  isFetchingAddAging: state.AddAgingReducer.isFetchingAddAging,
});

export default connect(mapStateToProps, mapDispatchToProps)(Aging);
