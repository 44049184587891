import { isEmpty, isNull, uniqBy } from "lodash";

const initialState = {

    profilePic: '',
    salaryListByIdLocal: {
        machineId: 0,
        basicSalary: 0,
        medicalAllowance: 0,
        petrolAllowance: 0,
        specialAllowance: 0,
        accomodationAllowance: 0,
        bonus: 0,
        overTime: 0,
        overTimeRate: 0,
        salaryTransfer: '',
        bankAccount: 0,
        lateTimeDeduct_1: 0,
        lateTimeDeduct_2: 0,
        exemptionAttendance: '',
        bankName: '',
        leaveAllowed: '',
        // activeStatus: Boolean,
        temporaryBlockSalary: '',
        reasonForBlockSalary: ''
    },

    setFieldsConsoleVar: '',

    isAddingEmployeeTypeData: false,
    addEmployeeType: [],
    employeeTypeList: [],
    isFetchingEmployeeTypeData: false,
    isUpdatingEmployeeTypeData: false,
    isDeletingEmployeeTypeData: false,

    isAddingHallData: false,
    addHall: [],
    addInstallment: [],
    isAddingInstallmentData: false,
    hallList: [],
    isFetchingHallData: false,
    isUpdatingHallData: false,
    isDeletingHallData: false,
    isDeletingCustomerData: false,
    employeeAdd: [],
    isAddingEmployee: false,
    employeeList: [],
    isFetchingEmployeeData: false,
    isAddingSubEventData: false,

    activeEmployeeList: [],
    isFetchingActiveEmployeeData: false,

    addSubEvent: [],
    departmentList: [],
    isFetchingSubEventData: false,
    subEventsListByEventID:[],
    isFetchingSubEventDataByEventID:false,
    isDeletingSubEventData: false,

    isAddingDesignationData: false,
    addDesignation: [],
    designationList: [],
    isFetchingDesignationData: false,
    isFetchingEmployeeDataById: false,
    employeeListById: [],
    isFetchingSalaryDataById: false,
    salaryListById: [],

    isFetchingLeaveData: false,
    casualLeaves: 0,
    maternityLeaves: 0,
    sickLeaves: 0,
    otherLeaves: 0,
    annualLeaves: 0,
    paternityLeaves: 0,
    shortLeaves: 0,
    specialLeaves: 0,
    bookingAddons: [],
    showEmployeeAdd: false,
    isAddingSalary: false,
    errorAddingSalary: false,
    successAddingSalary: false,

    salaryAdd: [],

    isUpdatingSalary: false,
    salaryUpdate: [],

    shiftControl: [],
    addShiftControl: [],
    isFetchingShiftControlData: false,
    isAddingShiftControl: false,

    fine: [],
    addFine: [],
    isFetchingFineData: false,
    isAddingFine: false,

    masterData: [],
    isFetchingMasterAccounts: false,
    isAddingAccounts: false,
    addAccounts: [],

    ltAccount: [],
    isFetchingLTAccount: false,
    isAddingLTAccount: false,
    addLTAccount: [],

    isAddingSTAccount: false,
    addSTAccount: [],

    isFetchingLTLedger: false,
    ltLedger: [],

    isFetchingSTLedger: false,
    stLedger: [],

    isFetchingIndividualSalary: false,
    indSalary: [],
    //Customer state
    addCustomerMarquee: [],
    customerMarqueeList: [],
    visitorMarqueeList: [],
    dashboardMarqueeList: [],
    isAddingCustomerDataMarquee: false,
    isFetchingCustomerDataMarquee: false,
    isFetchingDashboardMarquee: false,
    //Events state
    eventList: [],
    isAddingEventData: false,
    addEvent: [],
    isFetchingEventData: false,
    isUpdatingEventData: false,
    isDeletingEventData: false,
    totalPriceWithoutdisc:0,
        //booking state
        bookingList: [],
        bookingListWithStatus: [],
        isFetchingBookingDataWithStatus: false,
        hideFormList:false,
        iserror:false,
        bookingByIdList: [],
        bookingByIdListForEdit: [],
        isAddingBookingData:false,
        isCompleteBooking:false,
        completeBooking:[],
        isAddingbookingData: false,
        addbooking: [],
        isFetchingBookingData: false,
        isFetchingBookingByIdData: false,
        isFetchingBookingByIdDataForEdit: false,
        isUpdatingbookingData: false,
        isDeletingbookingData: false,
        bookingShiftList: [],
        isFetchingBookingShiftData: false,
        //customer
        customerByIdList: [],
        isFetchingCustomerByIdData: false,
    //Menu states
    menuList: [],
    isAddingMenuData: false,
    addMenu: [],
    isFetchingMenuData: false,
    isUpdatingMenuData: false,
    isDeletingMenuData: false,
    //expense state
    expenseList: [],
    isAddingExpenseData: false,
    addExpense: [],
    isFetchingExpenseData: false,
    isUpdatingExpenseData: false,
    isDeletingExpenseData: false,

    //Master Control states
    masterControlsList: [],
    isAddingMasterControlsData: false,
    addMasterControls: [],
    isFetchingMasterControlsData: false,
    isUpdatingMasterControlsData: false,
    isDeletingMasterControlsData: false,

    isFetchingprofitByID: false,
    profitByID: [],

    isFetchingprofitByDate: false,
    profitByDate: [],
    isFetchingBookingByCustomerId:false,
    bookingByCustomerId:[],
    isFetchingMarqueeReport:false,
    marqueeReport:[],
};


const MarqueeReducer = (state = initialState, action) => {
    // console.log(action, "Awww")
    switch (action.type) {

        case "REQUEST_GET_IMAGE_HR":
            return {
                ...state,
                // isFetchingEmployeeTypeData: true 
            };
        case "SUCCESS_GET_IMAGE_HR":
            return {
                ...state,
                profilePic: action.payload,
                // isFetchingEmployeeTypeData: false,
            };
        case "ERROR_GET_IMAGE_HR":
            return { ...state };

        //EmployeeType-Add
        case "REQUEST_ADD_EMPLOYEE_TYPE":
            return { ...state, isAddingEmployeeTypeData: true };
        case "SUCCESS_ADD_EMPLOYEE_TYPE":
            return {
                ...state,
                addEmployeeType: action.payload,
                isAddingEmployeeTypeData: false,
            };
        case "ERROR_ADD_EMPLOYEE_TYPE":
            return { ...state, isAddingEmployeeTypeData: false };

        //EmployeeType-Get

        case "REQUEST_GET_EMPLOYEE_TYPE":
            return { ...state, isFetchingEmployeeTypeData: true };
        case "SUCCESS_GET_EMPLOYEE_TYPE":
            return {
                ...state,
                employeeTypeList: action.payload.employee_type,
                isFetchingEmployeeTypeData: false,
            };
        case "ERROR_GET_EMPLOYEE_TYPE":
            return { ...state, isFetchingEmployeeTypeData: false, };

            //ERROR_SEARCH_MARQUEE_REPORT
            
        case "REQUEST_SEARCH_MARQUEE_REPORT":
            return { ...state, isFetchingMarqueeReport: true };
        case "SUCCESS_SEARCH_MARQUEE_REPORT":
            return {
                ...state,
                marqueeReport: action.payload,
                isFetchingMarqueeReport: false,
            };
        case "ERROR_SEARCH_MARQUEE_REPORT":
            return { ...state, isFetchingMarqueeReport: false, };

        //EmployeeType-Update
        case "REQUEST_UPDATE_EMPLOYEE_TYPE":
            return { ...state, isUpdatingEmployeeTypeData: true };
        case "SUCCESS_UPDATE_EMPLOYEE_TYPE":
            return { ...state, isUpdatingEmployeeTypeData: false };
        case "ERROR_UPDATE_EMPLOYEE_TYPE":
            return { ...state, isUpdatingEmployeeTypeData: false };

        //EmployeeType-Delete
        case "REQUEST_DELETE_EMPLOYEE_TYPE":
            return { ...state, isDeletingEmployeeTypeData: true };
        case "SUCCESS_DELETE_EMPLOYEE_TYPE":
            return { ...state, isDeletingEmployeeTypeData: false };
        case "ERROR_DELETE_EMPLOYEE_TYPE":
            return { ...state, isDeletingEmployeeTypeData: false };
        // +++++++++++++++++++++++++++++++++++++++++++++++;        
        // Add bookig
        case "REQUEST_ADD_BOOKING":
            return { ...state, isAddingBookingData: true };
        case "SUCCESS_ADD_BOOKING":
            return {
                ...state,
                addBooking: action.payload,
                isAddingBookingData: false,
            };
        case "ERROR_ADD_BOOKING":
            return { ...state, isAddingBookingData: false };
        case "SET_HIDE_FORM":
            return { ...state, 
                hideFormList: action.payload }; 
                //complete bookig
                case "REQUEST_COMPLETE_BOOKING":
            return { ...state, isAddingBookingData: true };
        case "SUCCESS_COMPLETE_BOOKING":
            return {
                ...state,
             //   completeBooking: action.payload,
                isCompleteBooking: false,
            };
        case "ERROR_COMPLETE_BOOKING":
            return { ...state, isCompleteBooking: false };
        //Hall-Add
        case "REQUEST_ADD_HALL":
            return { ...state, isAddingHallData: true };
        case "SUCCESS_ADD_HALL":
            return {
                ...state,
                addHall: action.payload,
                isAddingHallData: false,
            };
        case "ERROR_ADD_HALL":
            return { ...state, isAddingHallData: false };

        //Hall-Get

        case "REQUEST_GET_HALL":
            return { ...state, isFetchingHallData: true };
        case "SUCCESS_GET_HALL":
            return {
                ...state,
                hallList: action.payload,
                isAddingHallData: false,
                isFetchingHallData: false,
            };
        case "ERROR_GET_HALL":
            return { ...state };

        //Hall-Update
        case "REQUEST_UPDATE_HALL":
            return { ...state, isUpdatingHallData: true };
        case "SUCCESS_UPDATE_HALL":
            return { ...state, isUpdatingHallData: false };
        case "ERROR_UPDATE_HALL":
            return { ...state, isUpdatingHallData: false };

        //Hall-Delete
        case "REQUEST_DELETE_HALL":
            return { ...state, isDeletingHallData: false };
        case "SUCCESS_DELETE_HALL":
            return { ...state, isDeletingHallData: false };
        case "ERROR_DELETE_HALL":
            return { ...state, isDeletingHallData: false };
        //installment add
        case "REQUEST_ADD_INSTALLMENT":
            return { ...state, isAddingInstallmentData: true };
        case "SUCCESS_ADD_INSTALLMENT":
            return {
                ...state,
                addInstallment: action.payload,
                isAddingInstallmentData: false,
            };
        case "ERROR_ADD_INSTALLMENT":
            return { ...state, isAddingInstallmentData: false };
        //customer-delete
        case "REQUEST_DELETE_CUSTOMER":
            return { ...state, isDeletingCustomerData: false };
        case "SUCCESS_DELETE_CUSTOMER":
            return { ...state, isDeletingCustomerData: false };
        case "ERROR_DELETE_CUSTOMER":
            return { ...state, isDeletingCustomerData: false };
        //Event-Add
        case "REQUEST_ADD_EVENT":
            return { ...state, isAddingEventData: true };
        case "SUCCESS_ADD_EVENT":
            return {
                ...state,
                addEvent: action.payload,
                isAddingEventData: false,
            };
        case "ERROR_ADD_EVENT":
            return { ...state, isAddingEventData: false };
        //add customer
        case "REQUEST_ADD_CUSTOME_RMARQUEE":
            return { ...state, isAddingCustomerDataMarquee: true };
        case "SUCCESS_ADD_CUSTOMER_MARQUEE":
            return {
                ...state,
                addCustomerMarquee: action.payload,
                isAddingCustomerDataMarquee: false,
            };
        case "ERROR_ADD_CUSTOMER_MARQUEE":
            return { ...state, isAddingCustomerDataMarquee: false };

        //Event-Get

        case "REQUEST_GET_EVENT":
            return { ...state, isFetchingHallData: true };
        case "SUCCESS_GET_EVENT":
            return {
                ...state,
                eventList: action.payload,
                isFetchingEventData: false,
            };
        case "ERROR_GET_EVENT":
            return { ...state };
        //Get customer Marquee 
        case "REQUEST_GET_CUSTOMER_MARQUEE":
            return { ...state, isFetchingCustomerDataMarquee: true };
        case "SUCCESS_GET_CUSTOMER_MARQUEE":
            return {
                ...state,
                customerMarqueeList: action.payload.data,
                isFetchingCustomerDataMarquee: false,
            };
        case "ERROR_GET_CUSTOMER_MARQUEE":
            return { ...state };
        //get visitor
        case "ERROR_GET_EVENT":
            return { ...state };
        //Get customer Marquee 
        case "REQUEST_GET_VISITOR_MARQUEE":
            return { ...state, isFetchingCustomerDataMarquee: true };
        case "SUCCESS_GET_VISITOR_MARQUEE":
            return {
                ...state,
                visitorMarqueeList: action.payload.data,
                isFetchingCustomerDataMarquee: false,
            };
        case "ERROR_GET_VISITOR_MARQUEE":
            return { ...state };
        //get dashboard
        case "REQUEST_DASHBOARD_MARQUEE":
            return { ...state, isFetchingDashboardMarquee: true };
        case "SUCCESS_DASHBOARD_MARQUEE":
            return {
                ...state,
                dashboardMarqueeList: action.payload.map(x => ({
                    ...x,
                    start: new Date(x.start),
                    end: new Date(x.end)
                })),
                isFetchingDashboardMarquee: false,
            };
        case "ERROR_DASHBOARD_MARQUEE":
            return { ...state };
        //Sub event get
        case "REQUEST_GET_Sub_EVENT":
            return { ...state, isFetchingHallData: true };
        case "SUCCESS_GET_Sub_EVENT":
            return {
                ...state,
                eventList: action.payload,
                isFetchingEventData: false,
            };
        case "ERROR_GET_Sub_EVENT":
            return { ...state };
        // Bookig get
        case "REQUEST_GET_BOOKING":
            return { ...state, isFetchingBookingData: true };
        case "SUCCESS_GET_BOOKING":
            return {
                ...state,
                bookingList: action.payload,
                bookingByIdList:[],
                isFetchingBookingData: false,
            };
        case "ERROR_GET_BOOKING":
            return { ...state };
            ///
              case "REQUEST_GET_BOOKING_STATUS":
            return { ...state, isFetchingBookingData: true };
        case "SUCCESS_GET_BOOKING_STATUS":
            return {
                ...state,
                bookingListWithStatus: action.payload,
                // bookingByIdList:[],
                isFetchingBookingDataWithStatus: false,
            };
        case "ERROR_GET_BOOKING_STATUS":
            return { ...state };
        //bookig get by id
        case "REQUEST_GET_BOOKING_BY_ID":
            return { ...state, isFetchingBookingByIdData: true };
        case "SUCCESS_GET_BOOKING_BY_ID":
            console.log(Number(action.payload[0].booking_total_price), Number(action.payload[0].booking_discount), "ho");
            // console.log(totalPriceWithoutdisc)
            return {
                ...state,
                bookingByIdList: action.payload,
                bookingAddons: action.payload[0].booking_data.map(({ booking_data_price, master_control_item }) => [].concat(Object.assign(master_control_item, { 'booking_data_price': booking_data_price }))).flat(),
                isFetchingBookingByIdData: false,
                totalPriceWithoutdisc: (Number(action.payload[0].booking_total_price) * 100) / (100 - Number(action.payload[0].booking_discount))
            };
        case "ERROR_GET_BOOKING_BY_ID":
            return { ...state };
            ///
            case "REQUEST_GET_BOOKING_BY_ID_FOR_EDIT":
                return { ...state, isFetchingBookingByIdDataForEdit: true };
            case "SUCCESS_GET_BOOKING_BY_ID_FOR_EDIT":
                // console.log(Number(action.payload[0].booking_total_price), Number(action.payload[0].booking_discount), "ho");
                // console.log(totalPriceWithoutdisc)
                return {
                    ...state,
                    bookingByIdListForEdit: action.payload,
                    bookingAddons: action.payload.booking_data[0].booking_data.map(({ booking_data_price, master_control_item }) => [].concat(Object.assign(master_control_item, { 'booking_data_price': booking_data_price }))).flat(),
                    isFetchingBookingByIdDataForEdit: false,
                    totalPriceWithoutdisc: (Number(action.payload.booking_data[0].booking_total_price) * 100) / (100 - Number(action.payload.booking_data[0].booking_discount))
                };
            case "ERROR_GET_BOOKING_BY_ID_FOR_EDIT":
                return { ...state, };
            //get shifts
            case "REQUEST_GET_BOOKING_SHIFTS":
                return { ...state, isFetchingBookingShiftData: true };
            case "SUCCESS_GET_BOOKING_SHIFTS":
                return {
                    ...state,
                    // bookingShiftList: action.payload,
                    isFetchingBookingShiftData: false,
                    iserror:action.payload.result==false?false:true,
                    hideFormList:action.payload.result==false ? false : true,
                };
            case "ERROR_GET_BOOKING_SHIFTS":
                return { ...state,isFetchingBookingShiftData: false };
            /////////////////get customer by id
            case "REQUEST_GET_CUSTOMER_BY_ID":
                return { ...state, isFetchingCustomerByIdData: true };
            case "SUCCESS_GET_CUSTOMER_BY_ID":
               // console.log(Number(action.payload[0].booking_total_price),Number(action.payload[0].booking_discount),"ho");
               // console.log(totalPriceWithoutdisc)
               console.log(action.payload,'check3')
                return {
                    ...state,
                    customerByIdList: action.payload.data,
                    isFetchingCustomerByIdData: false,
                };
            case "ERROR_GET_CUSTOMER_BY_ID":
                return { ...state };
            //////////////////////////////////////////////////////
        /////////////////get customer by id
        case "REQUEST_GET_CUSTOMER_BY_ID":
            return { ...state, isFetchingCustomerByIdData: true };
        case "SUCCESS_GET_CUSTOMER_BY_ID":
            // console.log(Number(action.payload[0].booking_total_price),Number(action.payload[0].booking_discount),"ho");
            // console.log(totalPriceWithoutdisc)
            console.log(action.payload, 'check3')
            return {
                ...state,
                customerByIdList: action.payload.data,
                isFetchingCustomerByIdData: false,
            };
        case "ERROR_GET_CUSTOMER_BY_ID":
            return { ...state };
            // get bookig by  customer id
            case "REQUEST_GET_BOOKING_BY_CUSTOMER_ID":
                return { ...state, isFetchingBookingByCustomerId: true };
            case "SUCCESS_GET_BOOKING_BY_CUSTOMER_ID":
                return {
                    ...state,
                    bookingByCustomerId: action.payload,
                    isFetchingBookingByCustomerId: false,
                };
            case "ERROR_GET_BOOKING_BY_CUSTOMER_ID":
                return { ...state ,isFetchingBookingByCustomerId:false};
        //////////////////////////////////////////////////////
        case "EDIT_ADDONS":
            console.log('action.payload', action.payload)
            return {
                ...state,
                bookingAddons: action.payload,
                isFetchingBookingByIdData: false,
            };
        //edit booking
        case "SET_EDIT_BOOKING":
            let valueEmp = state.bookingByIdList[0];
            console.log('state.BookingByIdList ', state.bookingByIdList)
            console.log('valueEmp', valueEmp)

            let keyEmp = action.payload.key;
            console.log('keyEmp', keyEmp)

            valueEmp[keyEmp] = action.payload.value;
            console.log('valueEmp', valueEmp)
            let valueEmp2 = [valueEmp];
            console.log('valueEmp2', valueEmp2)
          
            return { ...state, bookingByIdList: [valueEmp] };
        case "SET_EDIT_BOOKING_CUSTOMER":
            let valueCust = state.bookingByIdList[0];
            let keyCus = action.payload.key;
            let keyCus2 = action.payload.key2;
            valueCust[keyCus] = action.payload.value;
            valueCust[keyCus2] = action.payload.value2;
            // let valueCust2 = [valueCust];
            console.log('EDIT_BOOKING_CUTOMER action.payload.data', action.payload)
            return { ...state, bookingByIdList: [valueCust] };
        //event-Update
        case "REQUEST_UPDATE_EVENT":
            return { ...state, isUpdatingEventData: true };
        case "SUCCESS_UPDATE_EVENT":
            return { ...state, isUpdatingEventData: false };
        case "ERROR_UPDATE_EVENT":
            return { ...state, isUpdatingEventData: false };


        //Menu-Add
        case "REQUEST_ADD_MENU":
            return { ...state, isAddingMenuData: true };
        case "SUCCESS_ADD_MENU":
            return {
                ...state,
                addMenu: action.payload,
                isAddingMenuData: false,
            };
        case "ERROR_ADD_MENU":
            return { ...state, isAddingMenuData: false };

        //Menu-Get

        case "REQUEST_GET_MENU":
            return { ...state, isFetchingMenuData: true };
        case "SUCCESS_GET_MENU":
            return {
                ...state,
                menuList: action.payload,
                isFetchingMenuData: false,
            };
        case "ERROR_GET_MENU":
            return { ...state };

        //menu-Update
        case "REQUEST_UPDATE_MENU":
            return { ...state, isUpdatingMenuData: true };
        case "SUCCESS_UPDATE_MENU":
            return { ...state, isUpdatingMenuData: false };
        case "ERROR_UPDATE_MENU":
            return { ...state, isUpdatingMenuData: false };
        //menu-Delete
        case "REQUEST_DELETE_MENU":
            return { ...state, isDeletingMenuData: false };
        case "SUCCESS_DELETE_MENU":
            return { ...state, isDeletingMenuData: false };
        case "ERROR_DELETE_MENU":
            return { ...state, isDeletingMenuData: false };
        //Master control-Add
        case "REQUEST_ADD_MASTER_CONTROL":
            return { ...state, isAddingMasterControlData: true };
        case "SUCCESS_ADD_MASTER_CONTROL":
            return {
                ...state,
                addMasterContr: action.payload,
                isAddingMasterControlData: false,
            };
        case "ERROR_ADD_MENU":
            return { ...state, isAddingMenuData: false };

        //master control get
        case "REQUEST_GET_MASTER_CONTROL":
            return { ...state, isFetchingMasterControlsData: true };
        case "SUCCESS_GET_MASTER_CONTROL":
            return {
                ...state,
                masterControlsList: action.payload,
                isFetchingMasterControlsData: false,
            };
        case "ERROR_GET_MASTER_CONTROL":
            return { ...state };

        //Menu-Get

        case "REQUEST_GET_MENU":
            return { ...state, isFetchingMenuData: true };
        case "SUCCESS_GET_MENU":
            return {
                ...state,
                menuList: action.payload,
                isFetchingMenuData: false,
            };
        case "ERROR_GET_MENU":
            return { ...state };

        //menu-Update
        case "REQUEST_UPDATE_MENU":
            return { ...state, isUpdatingMenuData: true };
        case "SUCCESS_UPDATE_MENU":
            return { ...state, isUpdatingMenuData: false };
        case "ERROR_UPDATE_MENU":
            return { ...state, isUpdatingMenuData: false };
        //menu-Delete
        case "REQUEST_DELETE_MENU":
            return { ...state, isDeletingMenuData: false };
        case "SUCCESS_DELETE_MENU":
            return { ...state, isDeletingMenuData: false };
        case "ERROR_DELETE_MENU":
            return { ...state, isDeletingMenuData: false };

        //Expense-Get

        case "REQUEST_GET_EXPENSE":
            return { ...state, isFetchingExpenseData: true };
        case "SUCCESS_GET_EXPENSE":
            return {
                ...state,
                expenseList: action.payload,
                isFetchingExpenseData: false,
            };
        case "ERROR_GET_EXPENSE":
            return { ...state };

        //menu-Update
        case "REQUEST_UPDATE_MENU":
            return { ...state, isUpdatingMenuData: true };
        case "SUCCESS_UPDATE_MENU":
            return { ...state, isUpdatingMenuData: false };
        case "ERROR_UPDATE_MENU":
            return { ...state, isUpdatingMenuData: false };
        //menu-Delete
        case "REQUEST_DELETE_MENU":
            return { ...state, isDeletingMenuData: false };
        case "SUCCESS_DELETE_MENU":
            return { ...state, isDeletingMenuData: false };
        case "ERROR_DELETE_MENU":
            return { ...state, isDeletingMenuData: false };

        //Hall-Delete
        case "REQUEST_DELETE_HALL":
            return { ...state, isDeletingHallData: false };
        case "SUCCESS_DELETE_HALL":
            return { ...state, isDeletingHallData: false };
        case "ERROR_DELETE_HALL":
            return { ...state, isDeletingHallData: false };

        //Emlployee
        case "REQUEST_ADD_EMPLOYEE":
            return { ...state, isAddingEmployee: true, showEmployeeAdd: false };
        case "SUCCESS_ADD_EMPLOYEE":
            return {
                ...state,
                employeeAdd: action.payload,
                isAddingEmployee: false,
                showEmployeeAdd: true
            };
        case "ERROR_ADD_EMPLOYEE":
            return { ...state, isAddingEmployee: false };


        case "REQUEST_GET_EMPLOYEE":
            return { ...state, isFetchingEmployeeData: true };
        case "SUCCESS_GET_EMPLOYEE":
            return {
                ...state,
                employeeList: action.payload.data,
                isFetchingEmployeeData: false,
            };
        case "ERROR_GET_EMPLOYEE":
            return { ...state, isFetchingEmployeeData: false };

        case "REQUEST_GET_ACTIVE_EMPLOYEE":
            return { ...state, isFetchingActiveEmployeeData: true };
        case "SUCCESS_GET_ACTIVE_EMPLOYEE":
            return {
                ...state,
                activeEmployeeList: action.payload.data,
                isFetchingActiveEmployeeData: false,
            };
        case "ERROR_GET_ACTIVE_EMPLOYEE":
            return { ...state, isFetchingActiveEmployeeData: false };


        /*  case "SET_EDIT_EMPLOYEE_FIELDS":
              let valueEmp = state.employeeListById;
              console.log('state.employeeListById ', state.employeeListById)
              console.log('valueEmp', valueEmp)
  
              let keyEmp = action.payload.key;
              console.log('keyEmp', keyEmp)
  
              valueEmp[keyEmp] = action.payload.value;
              console.log('valueEmp', valueEmp)
              let valueEmp2 = [valueEmp];
              console.log('valueEmp2', valueEmp2)
  
              console.log('EDIT_EMPLOYEE action.payload.data', action.payload)
  
              return { ...state, employeeListById: valueEmp };*/

        //SubEvent

        case "REQUEST_ADD_SUBEVENT":
            return { ...state, isAddingSubEventData: true };
        case "SUCCESS_ADD_SUBEVENT":
            return {
                ...state,
                addSubEvent: action.payload,
                isAddingSubEventData: false,
            };
        case "ERROR_ADD_SUBEVENT":
            return { ...state, isAddingSubEventData: false };

        //SubEvent-get
        case "REQUEST_GET_SUBEVENT":
            return { ...state, isFetchingSubEventData: true };
        case "SUCCESS_GET_SUBEVENT":
            return {
                ...state,
                subEventsList: action.payload,
                isFetchingSubEventData: false,
            };
        case "ERROR_GET_SUBEVENT":
            return { ...state, isFetchingSubEventData: false };
            /////////////////////////////
            case "REQUEST_GET_SUBEVENT_BY_EVENTID":
                return { ...state, isFetchingSubEventDataByEventID: true };
            case "SUCCESS_GET_SUBEVENT_BY_EVENTID":
                return {
                    ...state,
                    subEventsListByEventID: action.payload,
                    isFetchingSubEventDataByEventID: false,
                };
            case "ERROR_GET_SUBEVENT_BY_EVENTID":
                return { ...state, isFetchingSubEventDataByEventID: false };
        //SubEvent-getById

        case "REQUEST_GET_SUBEVENT_BY_DIVISIONID":
            return { ...state, isFetchingSubEventData: true };
        case "SUCCESS_GET_SUBEVENT_BY_DIVISIONID":
            return {
                ...state,
                departmentList: action.payload.data,
                isFetchingSubEventData: false,
            };
        case "ERROR_GET_SUBEVENT_BY_DIVISIONID":
            return { ...state, isFetchingSubEventData: false };
        //SubEvent-Delete
        case "REQUEST_DELETE_SUBEVENT":
            return { ...state, isDeletingSubEventData: true };
        case "SUCCESS_DELETE_SUBEVENT":
            return { ...state, isDeletingSubEventData: false };
        case "ERROR_DELETE_SUBEVENT":
            return { ...state, isDeletingSubEventData: false };
        //Designation
        case "REQUEST_ADD_DESIGNATION":
            return { ...state, isAddingDesignationData: true };
        case "SUCCESS_ADD_DESIGNATION":
            return {
                ...state,
                addDesignation: action.payload,
                isAddingDesignationData: false,
            };
        case "ERROR_ADD_DESIGNATION":
            return { ...state, isAddingDesignationData: false };


        case "REQUEST_GET_DESIGNATION":
            return { ...state, isFetchingDesignationData: true };
        case "SUCCESS_GET_DESIGNATION":
            return {
                ...state,
                designationList: action.payload.designation,
                isFetchingDesignationData: false,
            };
        case "ERROR_GET_DESIGNATION":
            return { ...state, isFetchingDesignationData: false };
        case "REQUEST_DELETE_DESIGNATION":
            return { ...state };
        case "SUCCESS_DELETE_DESIGNATION":
            return { ...state, designationList: state.designationList.filter(item => item.designation_id !== action.designation_id) };
        case "ERROR_DELETE_DESIGNATION":
            return { ...state };

        case "UPDATE_REQUEST_HR":
            return { ...state };
        case "UPDATE_SUCCESS_HR":
            //return { ...state, employeeList: state.employeeList.map(x => action.payload.find(({ employeeId }) => employeeId == x.employeeId) || x) };
            return { ...state, employeeList: action.payload };

        case "UPDATE_ERROR_HR":
            return { ...state };

        case "REQUEST_DELETE_HR":
            return { ...state };
        case "SUCCESS_DELETE_HR":
            return { ...state, employeeList: state.employeeList.filter(item => item.employeeId !== action.employeeId) };
        case "ERROR_DELETE_HR":
            return { ...state };
        //HR Policy
        case "SET_CASUAL_LEAVE":

            console.log("casualLeaves", state.casualLeaves)
            return { ...state, casualLeaves: action.payload };
        case "SET_SICK_LEAVE":
            console.log("sickLeaves", state.sickLeaves)
            return { ...state, sickLeaves: action.payload };
        case "SET_OTHER_LEAVE":
            console.log("otherLeaves", state.otherLeaves)
            return { ...state, otherLeaves: action.payload };
        case "SET_ANNUAL_LEAVE":
            console.log("annualLeaves", state.annualLeaves)
            return { ...state, annualLeaves: action.payload };
        case "SET_PATERNITY_LEAVE":
            console.log("paternityLeaves", state.paternityLeaves)
            return { ...state, paternityLeaves: action.payload };
        case "SET_SHORT_LEAVE":
            console.log("shortLeaves", state.shortLeaves)
            return { ...state, shortLeaves: action.payload };
        case "SET_SPECIAL_LEAVE":
            console.log("specialLeaves", state.specialLeaves)
            return { ...state, specialLeaves: action.payload };
        case "SET_MATERNITY_LEAVE":
            console.log("maternityLeaves", state.maternityLeaves)
            return { ...state, maternityLeaves: action.payload };

        case "REQUEST_GET_HR_POLICY":
            return { ...state, isFetchingLeaveData: true };
        case "SUCCESS_GET_HR_POLICY":
            return {
                ...state,
                casualLeaves: action.payload.policy[0].annual_leaves,
                maternityLeaves: action.payload.policy[0].maternity_leaves,
                sickLeaves: action.payload.policy[0].sick_leaves,
                otherLeaves: action.payload.policy[0].other_leaves,

                annualLeaves: action.payload.policy[0].annual_leaves,
                paternityLeaves: action.payload.policy[0].paternity_leaves,
                shortLeaves: action.payload.policy[0].short_leaves,
                specialLeaves: action.payload.policy[0].special_leaves,

                isFetchingLeaveData: false,
            };
        case "ERROR_GET_HR_POLICY":
            return { ...state, isFetchingLeaveData: false };

        case "REQUEST_GET_EMPLOYEE_BY_ID":
            return { ...state, isFetchingEmployeeDataById: true };
        case "SUCCESS_GET_EMPLOYEE_BY_ID":
            return {
                ...state,
                employeeListById: action.payload.data[0],
                isFetchingEmployeeDataById: false,
            };
        case "ERROR_GET_EMPLOYEE_BY_ID":
            return { ...state, isFetchingEmployeeDataById: false };


        case "REQUEST_GET_SALARY_BY_ID":
            return { ...state, isFetchingSalaryDataById: true };
        case "SUCCESS_GET_SALARY_BY_ID":
            return {
                ...state,
                salaryListById: action.payload.salary,
                isFetchingSalaryDataById: false,
            };
        case "ERROR_GET_SALARY_BY_ID":
            return { ...state, isFetchingSalaryDataById: false };


        case "REQUEST_ADD_SALARY":
            return { ...state, isAddingSalary: true };
        case "SUCCESS_ADD_SALARY":
            return {
                ...state,
                salaryAdd: action.payload,
                isAddingSalary: false,
                successAddingSalary: true
            };
        case "ERROR_ADD_SALARY":
            return { ...state, isAddingSalary: false, errorAddingSalary: true };



        //Salary-Update
        case "REQUEST_UPDATE_SALARY":
            return { ...state, isUpdatingSalary: true };
        case "SUCCESS_UPDATE_SALARY":
            return {
                ...state,
                salaryUpdate: action.payload,
                isUpdatingSalary: false,
            };
        case "ERROR_UPDATE_SALARY":
            return { ...state, isUpdatingSalary: false };


        case "SET_SALARY_FIELDS":
            let valueSalary = state.salaryListById[0];
            console.log('SetSalary valueSalary', valueSalary);

            console.log('action.payload', action.payload);

            let keySalary = action.payload.key;
            console.log('SetSalary keySalary', keySalary);
            console.log('SetSalary action.payload.data', action.payload);

            valueSalary[keySalary] = action.payload.value;
            console.log('SetSalary valueSalary[key]', valueSalary);

            let xo = [valueSalary]
            console.log('SetSalary xo', xo);

            console.log('SALARY action.payload.data', action.payload)

            return { ...state, salaryListById: [valueSalary] };



        //Shift Control
        case "REQUEST_GET_SHIFT_CONTROL":
            return { ...state, isFetchingShiftControlData: true };
        case "SUCCESS_GET_SHIFT_CONTROL":
            return {
                ...state,
                shiftControl: action.payload.result,
                isFetchingShiftControlData: false,
            };
        case "ERROR_GET_SHIFT_CONTROL":
            return { ...state, isFetchingShiftControlData: false };

        case "REQUEST_ADD_SHIFT_CONTROL":
            return { ...state, isAddingShiftControl: true };
        case "SUCCESS_ADD_SHIFT_CONTROL":
            return {
                ...state,
                addShiftControl: action.payload,
                isAddingShiftControl: false,
            };
        case "ERROR_ADD_SHIFT_CONTROL":
            return { ...state, isAddingShiftControl: false };


        case "REQUEST_DELETE_SHIFT_CONTROL":
            return { ...state };
        case "SUCCESS_DELETE_SHIFT_CONTROL":
            return { ...state, shiftControl: state.shiftControl.filter(item => item.shift_control_id !== action.shift_control_id) };
        case "ERROR_DELETE_SHIFT_CONTROL":
            return { ...state };

        case "REQUEST_UPDATE_SHIFT_CONTROL":
            return { ...state };
        case "SUCCESS_UPDATE_SHIFT_CONTROL":
            return { ...state, shiftControl: action.payload };

        case "ERROR_UPDATE_SHIFT_CONTROL":
            return { ...state };

        //Fine & Penalties
        case "REQUEST_GET_FINE":
            return { ...state, isFetchingFineData: true };
        case "SUCCESS_GET_FINE":
            return {
                ...state,
                fine: action.payload.result,
                isFetchingFineData: false,
            };
        case "ERROR_GET_FINE":
            return { ...state, isFetchingFineData: false };

        case "REQUEST_ADD_FINE":
            return { ...state, isAddingFine: true };
        case "SUCCESS_ADD_FINE":
            return {
                ...state,
                addFine: action.payload.result,
                isAddingFine: false,
            };
        case "ERROR_ADD_FINE":
            return { ...state, isAddingFine: false };


        case "REQUEST_DELETE_FINE":
            return { ...state };
        case "SUCCESS_DELETE_FINE":
            return { ...state, fine: state.fine.filter(item => item.fine_and_plenties_id !== action.fine_and_plenties_id) };
        case "ERROR_DELETE_FINE":
            return { ...state };

        case "REQUEST_UPDATE_FINE":
            return { ...state };
        case "SUCCESS_UPDATE_FINE":
            return { ...state, fine: action.payload };

        case "ERROR_UPDATE_FINE":
            return { ...state };
        //Master Control
        case "REQUEST_GET_MASTER_ACCOUNTS":
            return { ...state, isFetchingMasterAccounts: true };
        case "SUCCESS_GET_MASTER_ACCOUNTS":
            return {
                ...state,
                masterData: action.payload,
                isFetchingMasterAccounts: false,
            };
        case "ERROR_GET_MASTER_ACCOUNTS":
            return { ...state, isFetchingMasterAccounts: false };

        case "REQUEST_ADD_MASTER_ACCOUNTS":
            return { ...state, isAddingAccounts: true };
        case "SUCCESS_ADD_MASTER_ACCOUNTS":
            return {
                ...state,
                addAccounts: action.payload,
                isAddingAccounts: false,
            };
        case "ERROR_ADD_MASTER_ACCOUNTS":
            return { ...state, isAddingAccounts: false };
        case "RESET_MASTER_DATA":
            return { ...state, addAccounts: '' }

        //LT Advance
        case "REQUEST_GET_LT_ACCOUNT":
            return { ...state, isFetchingLTAccount: true };
        case "SUCCESS_GET_LT_ACCOUNT":
            return {
                ...state,
                ltAccount: action.payload.master_control,
                isFetchingLTAccount: false,
            };
        case "ERROR_GET_LT_ACCOUNT":
            return { ...state, isFetchingLTAccount: false };


        case "REQUEST_ADD_LT_ACCOUNT":
            return { ...state, isAddingLTAccount: true };
        case "SUCCESS_ADD_LT_ACCOUNT":
            return {
                ...state,
                addLTAccount: action.payload,
                isAddingLTAccount: false,
            };
        case "ERROR_ADD_LT_ACCOUNT":
            return { ...state, isAddingLTAccount: false };
        //ST Advance
        case "REQUEST_ADD_ST_ACCOUNT":
            return { ...state, isAddingSTAccount: true };
        case "SUCCESS_ADD_ST_ACCOUNT":
            return {
                ...state,
                addSTAccount: action.payload,
                isAddingSTAccount: false,
            };
        case "ERROR_ADD_ST_ACCOUNT":
            return { ...state, isAddingSTAccount: false };
        //LT Ledger
        case "REQUEST_GET_INFO_LT_LEDGER":
            return { ...state, isFetchingLTLedger: true };
        case "SUCCESS_GET_INFO_LT_LEDGER":
            return {
                ...state,
                ltLedger: action.payload,
                isFetchingLTLedger: false,
            };
        case "ERROR_GET_INFO_LT_LEDGER":
            return { ...state, isFetchingLTLedger: false };
        //ST Ledger
        case "REQUEST_GET_INFO_ST_LEDGER":
            return { ...state, isFetchingSTLedger: true };
        case "SUCCESS_GET_INFO_ST_LEDGER":
            return {
                ...state,
                stLedger: action.payload,
                isFetchingSTLedger: false,
            };
        case "ERROR_GET_INFO_ST_LEDGER":
            return { ...state, isFetchingSTLedger: false };


        case "REQUEST_GENERATE_SALARY":
            return {
                ...state,
                isFetchingIndividualSalary: true
            };
        case "SUCCESS_GENERATE_SALARY":
            return {
                ...state,
                indSalary: action.payload,
                isFetchingIndividualSalary: false,
            };
        case "ERROR_GENERATE_SALARY":
            return { ...state };


        //profit get by id
        case "REQUEST_GET_PROFIT_BY_BOOKING":
            return { ...state, isFetchingprofitByID: true };
        case "SUCCESS_GET_PROFIT_BY_BOOKING":
            console.log("profitByID", action.payload);
            return {
                ...state,
                isFetchingprofitByID: false,
                profitByID: action.payload,
            };
        case "ERROR_GET_PROFIT_BY_BOOKING":
            return { ...state, isFetchingprofitByID: false };

        //profit get by date
        case "REQUEST_GET_PROFIT_BY_DATE":
            return { ...state, isFetchingprofitByDate: true };
        case "SUCCESS_GET_PROFIT_BY_DATE":
            console.log("profitByID", action.payload);
            return {
                ...state,
                isFetchingprofitByDate: false,
                profitByDate: action.payload,
            };
        case "ERROR_GET_PROFIT_BY_DATE":
            return { ...state, isFetchingprofitByDate: false };


        default:
            return state;
    }
};
export default MarqueeReducer;
