import API from "../../../../../global/api";
import { isEmpty } from "lodash";
import logo from "../../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";


export const requestGetCustomer = () => {
    return {
        type: "REQUEST_GET_CUSTOMER"
    }
}
export const successGetCustomer = (data) => {
    return {
        type: "SUCCESS_GET_CUSTOMER",
        payload: data,
    }
}
export const errorGetCustomer = () => {
    return {
        type: "ERROR_GET_CUSTOMER"
    }
}
export const getCustomer = () => {
    return (dispatch) => {
        dispatch(requestGetCustomer())
        API.get('/quotation/get_quotation_customer').then((res) => {
            dispatch(successGetCustomer(res.data))
        }).catch((error) => {
            dispatch(errorGetCustomer())
        })
    }
}


export const requestAddInvoice = () => {
    return {
        type: "REQUEST_ADD_INVOICE",
    };
};
export const successAddInvoice = (data) => {
    return {
        type: "SUCCESS_ADD_INVOICE",
        payload: data,
    };
};
export const errorAddInvoice = () => {
    return {
        type: "ERROR_ADD_INVOICE",
    };
};
export const addQuotationApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddInvoice());
        API.post(`/quotation/add_quotation`, data).then((res) => {
            //   dispatch(getInvoice());
            dispatch(successAddInvoice(data));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddInvoice());
            handleDangerVisible();
        });
    };
};

export const resetReducer = () => {
    return {
        type: "RESET_REDUCER",
    };
};


export const requestGetInvoice = () => {
    return {
        type: "REQUEST_GET_QUOTE"
    }
}
export const successGetInvoice = (data) => {
    return {
        type: "SUCCESS_GET_QUOTE",
        payload: data,
    }
}
export const errorGetInvoice = () => {
    return {
        type: "ERROR_GET_QUOTE"
    }
}
export const getQuotationApi = () => {
    return (dispatch) => {
        dispatch(requestGetInvoice())
        API.get('/quotation/get_quotation').then((res) => {
            dispatch(successGetInvoice(res.data))
        }).catch((error) => {
            dispatch(errorGetInvoice())
        })
    }
}


export const getInvoicePdf = (data) => {
    return (dispatch) => {
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "Quotation Report";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const customerName = !isEmpty(data.level_five) ? "Customer Name: " + data.level_five.levelFiveName : "Customer Name: - ";
        const invoiceDate = !isEmpty(data.date) ? "Date: " + data.date : "Date: - ";
        const vatAmount = "Vat (5%): " + data.vat_amount;
        const grandTotal = "Grand Total: " + data.total_amount;
        const invoiceId = "Invoice Number: " + data.id;

        const headers = [
            [
                "Serial No",
                "Description",
                "UOM",
                "Quantity",
                "Price",
                "TotalPrice",
            ],
        ];
        const data1 = data.quotation_data.map((elt, index) => [
            index + 1,
            elt.description,
            elt.uom,
            elt.quantity,
            elt.price,
            elt.total_value,
        ]);
        let content = {
            startY: 220,
            startX: 10,
            head: headers,
            tableWidth: "auto",
            body: data1,
        };
        doc.addImage(logo, "PNG", 40, 15, 100, 100);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(13);
        doc.setFont("times", "normal");
        doc.text(companyAddress, 150, 60, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(14);
        doc.setFont("times", "bold");
        doc.text(title, 240, 120);
        doc.setFont("times", "normal");
        doc.text(invoiceId, 50, 150, 0, 20);
        doc.text(customerName, 50, 170, 0, 20);
        doc.text(invoiceDate, 50, 190, 0, 20);
        doc.text(`Description #: ${data.description}`, 50, 210, 0, 20);
        doc.autoTable(content);
        let finalY = doc.lastAutoTable.finalY;
        doc.text(vatAmount, 420, finalY + 30);
        doc.text(grandTotal, 420, finalY + 50);
        doc.setProperties({ title: `Quotation Report ${moment(new Date()).format("YYYY-MM-DD")}` });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
    }
}


export const DeleteRequestQuote = () => {
    return {
        type: "DELETE_REQUEST_QUOTE",
    };
};
export const DeleteSuccessQuote = (user_id) => {
    return {
        type: "DELETE_SUCCESS_QUOTE",
        user_id: user_id,
    };
};
export const DeleteErrorQuote = () => {
    return {
        type: "DELETE_ERROR_QUOTE",
    };
};
export const deleteQuote = (id) => {
    return dispatch => {
        dispatch(DeleteRequestQuote());
        API.delete(`/quotation/quotation_delete/${id}`).then(res => {
            dispatch(getQuotationApi());
            dispatch(DeleteSuccessQuote(id))
        }).catch((error) => {
            dispatch(DeleteErrorQuote())
        });
    }
};