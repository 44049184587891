import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import AddQuotation from "./AddQuotation";
import ViewQuotation from "./ViewQuotation";


const TotalQuotationData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    return (
        <>
            <Tabs >
                <Tab eventKey="AddQuotation" title="Add Quotation">
                    <AddQuotation />
                </Tab>
                <Tab eventKey="ViewQuotation" title="Quotation List">
                    <ViewQuotation />
                </Tab>
            </Tabs>

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalQuotationData);