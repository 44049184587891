import React, { useEffect, useState, forwardRef } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from 'react-bootstrap';
// import "./add-details.css";
import { resetReducer, } from "../AddDetails/AddDetails.actions";
import { getCustomerMarquee, addOrder, getOrder } from "./Order.actions";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";


const AddCategory = (props) => {
  const [orderNumber, setOrderNumber] = useState();
  const [orderDeliveryDate, setOrderDeliveryDate] = useState();
  const [orderDesc, setOrderDesc] = useState();
  const [orderDate, setOrderDate] = useState();
  const [customer_id, setcustomer_id] = useState();
  const [currency_symbol, set_currency_symbol] = useState();
  const [orderAmount, setOrderAmount] = useState();
  const [cashReceived, setCashReceived] = useState();
  const [pendingAmount, setPendingAmount] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [inputField, setInputField] = useState([{
    orderAmount: 0,
    cashReceived: 0,
    pendingAmount: 0,
  }]);

  const handleChange = (e, index, type) => {
    const list = [...inputField];
    if (type == 'intorderAmount') list[index]['orderAmount'] = e.target.value;
    if (type == 'cashReceived') list[index]['cashReceived'] = e.target.value;
    if (type == 'cashReceived') list[index]['pendingAmount'] = list[index]['orderAmount'] - list[index]['cashReceived'];
    setInputField(list);
  };


  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  function uploadCategory() {
    props.addOrder(
      {
        orderNumber: orderNumber,
        orderDeliveryDate: orderDeliveryDate,
        orderDesc: orderDesc,
        orderDate: orderDate,
        customer_id: customer_id.value,
        orderAmount: Number(inputField[0].orderAmount),
        cashReceived: Number(inputField[0].cashReceived),
        pendingAmount: inputField[0].pendingAmount,
        currency_symbol: currency_symbol.value
      },
      handleVisible,
      handleDangerVisible
    );
    setOrderNumber("");
    setOrderDeliveryDate("");
    setOrderDesc("");
    setOrderDate("");
    setcustomer_id("");
    setOrderAmount("");
    setCashReceived("");
    setPendingAmount("");
    setInputField([{
      orderAmount: 0,
      cashReceived: 0,
      pendingAmount: 0,
    }]);
  }

  const getCustomerList = props.customerMarqueeList.map((x) => {
    let data = { value: x.customer_id, label: `${x.customer_cnic} / ${x.customer_name} ` };
    return data;
  });

  const cu = [
    { symbol: "PKR", name: "Pakistani rupee" },
    { symbol: "AED", name: "UAE dirham" },
    { symbol: "USD", name: "United States dollar" },
    { symbol: "AUD", name: "Australian dollar" },
    { symbol: "CAD", name: "Canadian dollar" },
    { symbol: "GBP", name: "British pound" },
    { symbol: "EUR", name: "European Euro" },
    { symbol: "JPY", name: "Japanese yen" },
    { symbol: "NZD", name: "New Zealand dollar" },
    { symbol: "SAR", name: "Saudi riyal" },
    { symbol: "NZD", name: "New Zealand dollar" },
  ]

  const currencyList = cu.map((x) => {
    let data = { value: x.symbol, label: `${x.name} / ${x.symbol} ` };
    return data;
  });

  useEffect(() => {
    props.getCustomerMarquee();
    props.getOrder();
    // props.resetState();
  }, []);
  return (
    <>
      {props.isFetchingwareHouseList || props.isAddingOrderData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingOrderData ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Label>
                  <b> Select Customer *</b>
                </Form.Label>
                <Select
                  placeholder="Select Customer"
                  onChange={setcustomer_id}
                  options={getCustomerList}
                />
              </Col>
              <Col>
                <Form.Label>
                  <b> Select Currency *</b>
                </Form.Label>
                <Select
                  placeholder="Select Currency"
                  onChange={set_currency_symbol}
                  options={currencyList}
                />
              </Col>
            </Row>

            <Row>
              {/* <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Order Name </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Order Name"
                    value={orderType}
                    onChange={(e) => setOrderType(e.target.value)}
                  />
                </Form.Group>
              </Col> */}
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Manual Order Number *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Manual Order Number"
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Order Description </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Order Description"
                    value={orderDesc}
                    onChange={(e) => setOrderDesc(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Form.Label>
                    <b>Order Date *</b>
                  </Form.Label>
                  <Form.Control
                    id="expextedDate"
                    type="date"
                    // min={moment().format("YYYY-MM-DD")}
                    value={orderDate}
                    onChange={(e) => setOrderDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Form.Label>
                    <b>Order Delivery Date *</b>
                  </Form.Label>
                  <Form.Control
                    id="expextedDate"
                    type="date"
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    value={orderDeliveryDate}
                    onChange={(e) => setOrderDeliveryDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>

            </Row>

            {inputField.map((item, i) => {
              return (
                <Row>
                  <Col lg="4" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Order Amount *</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Order Amount"
                        value={item.orderAmount}
                        onChange={e => handleChange(e, i, 'intorderAmount')}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Paid Payment *</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Paid Payment"
                        value={item.cashReceived}
                        onChange={e => handleChange(e, i, 'cashReceived')}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Rest Payment *</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Rest Payment"
                        disabled={true}
                        value={item.pendingAmount}
                      // onChange={e => handleChange(e, i, 'intpendingAmount')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )
            })}


            <div className="sendDiv">
              <Button className="sendButton" onClick={() => { uploadCategory(); }}> Save </Button>
            </div>

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addOrder: (data, handleVisible, handleDangerVisible) => dispatch(addOrder(data, handleVisible, handleDangerVisible)),
    getCustomerMarquee: () => dispatch(getCustomerMarquee()),
    getOrder: () => dispatch(getOrder()),
    resetState: () => dispatch(resetReducer()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingOrderData: state.OrderReducer.isAddingOrderData,
  customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
  isFetchingOrder: state.OrderReducer.isFetchingOrder,
  listOrder: state.OrderReducer.listOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);
