import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import AddOrder from "./AddOrder";
import ListOrder from "./ListOrder";
import ListCompleteOrder from "./ListCompleteOrder";
import GetOrderPayment from "./GetOrderPayment";
import OrderPrint from "./OrderPDF";

const TotalOrder = (props) => {
    return (
        <>
            <Tabs>
                <Tab eventKey="AddOrder" title="Add Order">
                    <AddOrder />
                </Tab>
                <Tab eventKey="OrderList" title="Running Orders">
                    <ListOrder />
                </Tab>
                <Tab eventKey="CompleteOrderList" title="Shipped Orders">
                    <ListCompleteOrder />
                </Tab>
                <Tab eventKey="ReceiveOrderPayment" title="Receive Order Payment">
                    <GetOrderPayment />
                </Tab>
                <Tab eventKey="OrderPrint" title="Order Print">
                    <OrderPrint />
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => { return {} };
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalOrder);