import React, { useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { getOrder, getOrderWithOrderIdApi, addOrderPaymentApi, getOrderWithCustomerId, getOrderPrintApi } from './Order.actions';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import { isEmpty, find } from "lodash";
import Select from 'react-select';


const GetOrderPayment = (props) => {
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [paidAmount, setPaidAmount] = useState(0);
    const [remaininAmount, setRemainingAmount] = useState(0);
    const [newPaidAmount, setNewpaidAmount] = useState(0);
    const [submit, setSubmit] = useState(false);
    const [customerName, setCustomerName] = useState();
    const [customerNameValue, setCustomerNameValue] = useState();
    const [customerNameLabel, setCustomerNameLabel] = useState();
    const [orderValue, setOrderValue] = useState();
    const [orderLabel, setOrderLabel] = useState();

    function uploadGetOrderPaymentAmount() {
        props.getOrderPrintApi(orderValue)
    }
    const handleVisible = () => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Ok",
        });
    }
    const clearStates = () => {
        setSubmit(false);
    }

    const handleChangeCustomer = (selectedOption) => {
        setCustomerName(selectedOption.value);
        setCustomerNameLabel(selectedOption.label);
        setCustomerNameValue(selectedOption.value);
        props.getOrderWithCustomerId(selectedOption.value);
    };
    const getCustomerList = props.customerMarqueeList.map((x) => {
        let data = { value: x.customer_id, label: `${x.customer_cnic} / ${x.customer_name} ` };
        return data;
    });
    const handleChangeGetOrder = (selectedOption) => {
        setOrderValue(selectedOption.value);
        setOrderLabel(selectedOption.label);
        props.getOrderWithOrderIdApi(selectedOption.value);
    };
    const getListOrderWithCustomerId = !isEmpty(props.listOrderWithCustomerId) && props.listOrderWithCustomerId.map((x) => {
        let data = {
            value: x.orderId,
            label: x.orderNumber,
        };
        return data;
    });

    useEffect(() => {
        // props.getOrder();
        setPaidAmount(0);
    }, []);

    return (
        <>
            {(props.isFetchingCustomerDataMarquee) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row style={{ marginTop: '26px' }}>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select Customer *</b>
                                    </Form.Label>
                                    <Select
                                        value={{ label: customerNameLabel }}
                                        isDisabled={isEmpty(getCustomerList)}
                                        placeholder="Select Customer"
                                        onChange={(e) => handleChangeCustomer(e)}
                                        options={isEmpty(getCustomerList) ? [] : getCustomerList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        <b>Select Order *</b>
                                    </Form.Label>
                                    <Select
                                        value={{ label: orderLabel }}
                                        isDisabled={isEmpty(getListOrderWithCustomerId)}
                                        placeholder="Select Order"
                                        onChange={(e) => handleChangeGetOrder(e)}
                                        options={isEmpty(getListOrderWithCustomerId) ? [] : getListOrderWithCustomerId}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            {isEmpty(customerNameLabel) || isEmpty(orderLabel) ? (
                                <Button variant="contained" size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" size="large" style={{ backgroundColor: 'black', color: 'white' }} onClick={uploadGetOrderPaymentAmount}> SAVE </Button>
                            )}
                        </div>

                    </Form>
                </Container>
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addOrderPaymentApi: (data, handleVisible, handleDangerVisible) => dispatch(addOrderPaymentApi(data, handleVisible, handleDangerVisible)),
        getOrderWithCustomerId: (id) => dispatch(getOrderWithCustomerId(id)),
        getOrderWithOrderIdApi: (orderId) => dispatch(getOrderWithOrderIdApi(orderId)),
        getOrderPrintApi: (orderId) => dispatch(getOrderPrintApi(orderId))
    };
};
const mapStateToProps = (state, ownProps) => ({
    orderListWithOrderId: state.OrderReducer.orderListWithOrderId,
    customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
    isFetchingCustomerDataMarquee: state.MarqueeReducer.isFetchingCustomerDataMarquee,
    listOrderWithCustomerId: state.OrderReducer.listOrderWithCustomerId,
});

export default connect(mapStateToProps, mapDispatchToProps)(GetOrderPayment);

