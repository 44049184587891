import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Route } from 'react-router-dom';
import { getLogin } from "../../admin_views/accounts/account.actions"
import {Alert } from 'react-bootstrap';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import './style.css';
import { Modal, ModalHeader, ModalFooter, ModalBody, Row } from 'reactstrap';
var bg = require('../../../../src/Images/bg.jpg');
var back = require(`../../../Images/bg.jpg`);
function Login(props) {
  const [email, setEmail] = React.useState("");
  const [pass, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);
  window.localStorage.clear()

  const [show, setShow] = React.useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleLogin = () => {

    props.getLogin({
      username: email,
      password: pass,
    })

    handleShow()
  }
  return (
    <div >
      <div className="login-block">
        <div className="container">
          <div className="row">
            <div className="col-md-4 login-sec">
              <h2 className="text-center">Admin Login</h2>

              <div className="form-group">
                <label for="exampleInputEmail1" className="text-uppercase">Username</label>
                <input type="text" id="standardbasic" className="form-control" placeholder="" onBlur={(e) => setEmail(e.target.value)} />

              </div>
              <div className="form-group">
                <label for="exampleInputPassword1" className="text-uppercase">Password</label>

                <KeyboardEventHandler
                  handleKeys={['Enter']}
                  onKeyEvent={(key, e) => handleLogin()} >
                  <div style={{ display: "grid" }} className="form-group">

                    <input type="password" id="standard-basic" className="form-control" placeholder="" onChange={(e) => setPassword(e.target.value)} />
                  </div>
                </KeyboardEventHandler>


              </div>

              <div className="form-check">
                <button onClick={() => handleLogin()} className="btn btn-login cus-btn float-right">Sign In</button>
              </div>
              <Route render={({ history }) => (
                <button
                  type='button'
                  style={{ color: 'white', background: 'black', borderRadius: '5px', borderColor: 'silver', padding: '10px', fontWeight: 'bold' }}
                  onClick={() => { history.push('/user') }}
                >
                  Go Back to User Login
                </button>
              )} />
            </div>
            <Modal show={show} onHide={handleClose}>
                            </Modal>
            <div className="col-md-8 banner-sec">
              
            </div>
          </div>
        </div>


        <div className="modal">
          {props.isWrongCredentials == true && <Modal isOpen={show}>
            <ModalHeader><h4>Login Unsuccessful</h4></ModalHeader>
            <ModalBody><h5>Something is Wrong</h5></ModalBody>
            <ModalFooter>
              <Row className="close">
                <Button onClick={handleClose}>
                  Close
                </Button>
              </Row>
            </ModalFooter>
          </Modal>}
        </div>
      </div>
    </div>
  );

}

const mapDispatchToProps = dispatch => {
  return {
    getLogin: (data) => dispatch(getLogin(data)),
  }
}

const mapStateToProps = (state, ownProps) => ({
  isWrongCredentials: state.userReducerAdmin.isWrongCredentials,
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);