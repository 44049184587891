import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import {
  addExpense,
  getExpense,
  deleteExpense,
  getBooking,
  getMasterControl /*UpdateHRExpense */,
  getEvent,
  getSubEvent,
  getHall,
  getMenu,
  updateBooking,
  getCustomerMarquee,
  getBookingById,
  setEditBooking,
  setEditBookingCustomer,
  editAddons,
  completeBooking,
  getBookingStatus,
  getBookingByIdForEdit
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find, sumBy, pick } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import { number } from "yargs";

const EditBookingMarquee = (props) => {
  const [bookingId, setBookingId] = useState("");
  const [bookingName, setBookingName] = useState("");
  const [bookingshow, setBookingShow] = useState(0);
  //const [bookingName, setBookingName] = useState("");
  const [bookingPerPersonPrice, setBookingPerPersonPrice] = useState(0);
  const [bookingAdvance, setBookingAdvance] = useState(0);
  const [bookingRemainingPrice, setBookingRemainingPrice] = useState(0);
  const [bookingTotalPrice, setBookingTotalPrice] = useState(0);
  const [bookingDiscount, setBookingDiscount] = useState(0);
  const [bookingPerson, SetBookingPerson] = useState(1);
  const [bookingDescription, setBookingDescription] = useState("");
  const [menuId, setMenuId] = useState("");
  const [totalPriceDummy, settotalPriceDummy] = useState(0);
  const [menuName, setMenuName] = useState("");
  const [menuPrice, setMenuPrice] = useState("");
  const [menuPerHeadPrice, setMenuPerHeadPrice] = useState("");
  const [chargingPrice, setChargingPrice] = useState("");
  const [addonsPrice,setAddonsPrice]=useState(0);
  const [addonsPrice1,setAddonsPrice1]=useState(0);
  const [expenseDescription, setExpenseDescription] = useState();
  const [submit, setSubmit] = useState(false);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [hallId, setHallId] = useState("");
  const [hallName, setHallName] = useState("");
  const [hallCapacity, setHallCapacity] = useState(0);
  const [hallTotolPrice, setHallTotalPrice] = useState(0);
  const [hallPerHeadPrice, setHallPerHeadPrice] = useState(0);
  const [eventId, setEventId] = useState("");
  const [eventName, setEventName] = useState("");
  const [subEventId, setSubEventId] = useState(0);
  const [subEventName, setSubEventName] = useState("");
  const [eventShiftId, setEventShiftId] = useState("");
  const [eventShiftName, setEventShiftName] = useState("");
  const [bookingTypeId, setBookingTypeId] = useState(0);
  const [bookingTypeName, setBookingTypeName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState(0);
  const [state, setState] = React.useState({
    columns: [
      { title: "Bookig Name", field: "booking.booking_name" },
      { title: "Expense Description", field: "expense_desc" },
    ],
  });
  const [stateDishes, setStateDishes] = React.useState({
    columns: [
      {
        title: "Dish Name",
        field: "master_control_item.master_control_item_name",
      },
      { title: "Price", field: "booking_data_price" },
    ],
  });
  const [inputField, setInputField] = useState([
    {
      master_control_item_id: "",
      booking_data_price: 0,
    },
  ]);
  const handleAddInput = () => {

    props.editAddons([
      ...props.bookingAddons,
      { master_control_item_id: "", booking_data_price: "" },
    ]);
    console.log(props.bookingAddons,"props.bookingAddons")
  };

  const handleRemoveInput = (index) => {
    const list = [...props.bookingAddons];
    list.splice(index, 1);
    props.editAddons(list)

    const addonsP=sumBy(list,"booking_data_price")
    console.log(addonsP,"tttttttttttttttt")
    setAddonsPrice(addonsP);
    let a = addonsP- Number(sumBy(props.bookingByIdList[0].booking_data, "booking_data_price"))
    let p=totalPriceDummy+a;
    let p1=(p*props.bookingByIdList[0].booking_discount)/100;
      let p2=(p-p1);
      props.setEditBooking({key:"booking_total_price",value:p})
      props.setEditBooking({key:"booking_payable_price",value:p2})
    console.log(p,"ppp");
   /* console.log(props.totalPriceWithoutdisc,"ho gya")
      let p=props.totalPriceWithoutdisc+Number(a) 
      let p1=(p*props.bookingByIdList[0].booking_discount)/100;
      let p2=(p-p1);
      props.setEditBooking({key:"booking_total_price",value:p2})
  //  setInputField(list);*/
  };
  const handleChange = (e, index, type) => {
    let z = find(
      props.allInventory,
      (x) => x.master_control_item_id == e.value
    );
    const { name, value } = e;
    const list = [...props.bookingAddons];
    if (type == "intname") list[index]["master_control_item_id"] = e.value; 
    if (type == "intname") {list[index]["master_control_item_name"] = e.label
  setAddonsPrice(0);
  console.log(addonsPrice,"tas")
  console.log(list,"listtttt")
  };
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    // if (type == 'intname') setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intpricec") {

       list[index]["booking_data_price"] = Number(e.target.value);
       console.log(list,"listtttt222")
      props.editAddons(list)
      // const addonsP=sumBy(list,"booking_data_price")
      // console.log(addonsP,"tttttttttttttttt")
      // setAddonsPrice(addonsP);
      // let p=props.bookingByIdList[0].booking_total_price+Number(addonsP)
      // props.setEditBooking({key:"booking_total_price",value:p})
    }
    if (type == "intpriceb") {
      console.log(props.bookingByIdList,"listttttttttttttttttttt")
      list[index]["booking_data_price"] = Number(e.target.value);
      props.editAddons(list)
      const addonsP=sumBy(list,"booking_data_price")
      console.log(addonsP,"tttttttttttttttt")
      setAddonsPrice(addonsP);
      //let tp=(props.bookingByIdList[0].booking_total_price*100)/(100-props.bookingByIdList[0].booking_discount);
     // console.log(tp,"tptp")
     console.log(totalPriceDummy,"fi")
      let a = addonsP- Number(sumBy(props.bookingByIdList[0].booking_data, "booking_data_price"))
      console.log(a,"aaaaa")
      setAddonsPrice1(a);
      let b=0;
      if(totalPriceDummy ==0&&b ==0){
        console.log(props.bookingByIdList[0].booking_total_price,"tas1")
        console.log(a,"tas1")
        let addoDis=(a*props.bookingByIdList[0].booking_discount)/100;
        let addoDis1=a-addoDis;
        let add=props.bookingByIdList[0].booking_total_price+addoDis1;
        console.log(addoDis1,add,"tas1111111111")
        props.setEditBooking({key:"booking_total_price",value:a});
        props.setEditBooking({key:"booking_payable_price",value:add});
        console.log(props.bookingByIdList[0].booking_total_price,"tas111")
        let totalV=props.bookingByIdList[0].booking_total_price*100;
        let x=100-props.bookingByIdList[0].booking_discount
        let totalV1=totalV/x;
        let tt=totalV1-a
        settotalPriceDummy(tt);
        //console.log(totalPriceDummy,"tas2")
        console.log(totalV1,"tas2")
        b=1;
      }
      else{
        console.log(totalPriceDummy,"tas33")
       let add=totalPriceDummy+a;
       let addoDis=(add*props.bookingByIdList[0].booking_discount)/100;
       let addoDis1=add-addoDis;
       props.setEditBooking({key:"booking_total_price",value:add})
       props.setEditBooking({key:"booking_payable_price",value:addoDis1})}
     // settotalPriceDummy(tp+a);
     // console.log(tp+a,"ho gya")
    /* console.log(props.totalPriceWithoutdisc,"ho gya")
      let p=props.totalPriceWithoutdisc+Number(a) 
      let p1=(p*props.bookingByIdList[0].booking_discount)/100;
      let p2=(p-p1);
      props.setEditBooking({key:"booking_total_price",value:p2})*/

    }
     
  // setInputField(list);
    // list[index]['booking_data_price'] = "";
  };// value={{label:isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].event.event_name}}
 // let Ad = props.bookingByIdList[0].booking_data.map(({ master_control_item }) => [].concat(master_control_item)).flat();
  //let  inputField1 = props.bookingByIdList[0].booking_data;
  function completeBooking(id){
    props.completeBooking(id);

  }
 

  function updateBooking() {
    props.updateBooking(
      {
       // booking_name: bookingName,
        booking_desc: props.bookingByIdList[0].booking_desc,
        booking_status: "Pending",
        booking_person: props.bookingByIdList[0].booking_person,
        booking_price_per_person: props.bookingByIdList[0].booking_price_per_person,
        booking_total_price: props.bookingByIdList[0].booking_total_price,
        booking_payable_price: props.bookingByIdList[0].booking_payable_price,
        booking_advance: sumBy(props.bookingByIdList[0].booking_installments,"installment_price"),
        booking_discount: props.bookingByIdList[0].booking_discount,
        booking_remaining_price: props.bookingByIdList[0].booking_remaining_price,
        event_id: props.bookingByIdList[0].event_id,
        event_shift_id: props.bookingByIdList[0].event_shift_id,
        hall_id: props.bookingByIdList[0].hall_id,
        sub_event_id: props.bookingByIdList[0].sub_event_id,
        menu_id: props.bookingByIdList[0].menu_id,
        customer_id: props.bookingByIdList[0].customer_id,
        booking_type:props.bookingByIdList[0].booking_type,
        data: props.bookingAddons,
      },
      bookingId,
      handleVisible,
      handleDangerVisible
    );
    setBookingShow(0)
  }
  const expenseData = isEmpty(props.expenseList)
    ? []
    : props.expenseList.reverse();
  const inventory = props.masterControlsList.map((x) => {
    let data = {
      value: x.master_control_item_id,
      label: x.master_control_item_name,
    };
    return data;
  });

  const clearStates = () => {
    setHallName("");
    setEventName("");
    setSubEventName("");
    setChargingPrice("");
    setBookingTotalPrice(0);
    setBookingPerPersonPrice(0);
   // setBookingName("");
    setBookingDescription("");
    setBookingAdvance(0);
    setBookingDiscount(0);
    SetBookingPerson(0);
    setBookingRemainingPrice(0);
    setSubEventName("");
    setSubEventId(0);
    setSubmit(false);
  };
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };
 
  function getSiglehall(id){
    console.log(id, props.hallList,"id")
   let hall1= !isEmpty(props.hallList) && find(props.hallList, x => x.hall_id ==id);
   console.log(hall1, "custm11");
   return hall1;
  }
  const handleChangeHall = (selectedOption) => {
   // clearStates();
    console.log(selectedOption, "sop");
    setHallId(selectedOption.value);
    setHallName(selectedOption.label);
    let hallId= selectedOption.value;
    const getHall =!isEmpty(props.hallList) && find(props.hallList, x => x.hall_id ==hallId);
   
    setHallCapacity(getHall.hall_sitting_capacity);
 
    setHallTotalPrice(getHall.hall_per_function_charges);

    setHallPerHeadPrice(getHall.hall_per_person_charges);
    
    let p=Number(getHall.hall_per_function_charges)+Number(chargingPrice);
   setBookingTotalPrice(p);
   props.setEditBooking({ key: "hall_id", value: selectedOption.value })
    props.setEditBookingCustomer({ key: "hall_id", value: selectedOption.value, key2:"hall", value2: getSiglehall(selectedOption.value) })
  };
  const getHallList =
    !isEmpty(props.hallList) &&
props.hallList.map((x) => {
      let data = { value: x.hall_id, label: x.hall_name };
      return data;
    });
  console.log("gethalllist", getHallList);
  const getBookingList =
    !isEmpty(props.bookingListWithStatus) &&
    props.bookingListWithStatus.map((x) => {
      let data = { value: x.booking_id, label: `${x.booking_id} / ${x.booking_name} / ${x.booking_date}`};
      return data;
    });

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
 

  const handleChangeCustomer = (selectedOption) => {
    console.log(selectedOption, "sop");
    setCustomerId(selectedOption.value);
    setCustomerName(selectedOption.label);
    props.setEditBooking({ key: "customer_id", value: selectedOption.value })
    props.setEditBookingCustomer({ key: "customer_id", value: selectedOption.value, key2:"customer_table", value2: getSigleCustomer(selectedOption.value) })
  };
  function getSigleEvent(id){
    console.log(id, props.eventList,"id")
   let event1= !isEmpty(props.eventList) && find(props.eventList, x => x.event_id ==id);
   console.log(event1, "custm11");
   return event1;
  }
  const handleChangeEvent = (selectedOption) => {
    console.log(selectedOption, "sop");
    setEventId(selectedOption.value);
    setEventName(selectedOption.label);
    props.setEditBooking({ key: "event_id", value: selectedOption.value })
    props.setEditBookingCustomer({ key: "event_id", value: selectedOption.value, key2:"event", value2: getSigleEvent(selectedOption.value) })
  };
  const getEventList =
    !isEmpty(props.eventList) &&
    props.eventList.map((x) => {
      let data = { value: x.event_id, label: x.event_name };
      return data;
    });
  console.log("getEventList", getEventList);
 
  function getSigleSubEvent(id){
    console.log(id, props.subEventsList,"id")
   let subEvent1= !isEmpty(props.subEventsList) && find(props.subEventsList, x => x.sub_event_id ==id);
   console.log(subEvent1, "custm11");
   return subEvent1;
  }
  const handleChangeSubEvent = (selectedOption) => {
    console.log(selectedOption, "sop");
    setSubEventId(selectedOption.value);
    setSubEventName(selectedOption.label);
    props.setEditBooking({ key: "sub_event_id", value: selectedOption.value })
    props.setEditBookingCustomer({ key: "sub_event_id", value: selectedOption.value, key2:"sub_event", value2: getSigleSubEvent(selectedOption.value) })
  };
  const handleChargePrice= (e) => {
    props.setEditBooking({key:"charging_price",value:e.target.value})
   let p1=Number(e.target.value)*Number(props.bookingByIdList[0].booking_person);
   let p2=Number(props.bookingByIdList[0].hall.hall_per_function_charges)+p1;
   let p=Number(sumBy(props.bookingByIdList[0].booking_data,"booking_data_price"))+p2+Number(addonsPrice1)

   let disc=0
   props.bookingByIdList[0].booking_discount_type=="Cash"?
   disc=props.bookingByIdList[0].booking_discount:
   disc=(p*Number(props.bookingByIdList[0].booking_discount)/100);
   let discPrice=(p-Number(disc))
   settotalPriceDummy(p);
   props.setEditBooking({key:"booking_total_price",value:p})
   props.setEditBooking({key:"booking_payable_price",value:discPrice})
   let remPrice=props.bookingByIdList[0].booking_payable_price-sumBy(props.bookingByIdList[0].booking_installments,"installment_price");
                    props.setEditBooking({key:"booking_remaining_price", value:remPrice})
};
  const getSubEventsList =
    !isEmpty(props.subEventsList) &&
    props.subEventsList.map((x) => {
      let data = { value: x.sub_event_id, label: x.sub_event_name };
      return data;
    });
  console.log("getSubEventList", getSubEventsList);
  let shiftData = [
    {
      event_shift_id: 1,
      event_shift_name: "Morning",
      event_start_time: "08:00",
      event_end_time: "12:30",
    },
    {
      event_shift_id: 2,
      event_shift_name: "After Noon",
      event_start_time: "12:31",
      event_end_time: "06:00",
    },
    {
      event_shift_id: 3,
      event_shift_name: "Evening",
      event_start_time: "06:01",
      event_end_time: "04:00",
    },
  ];
  
  function getSigleEventShift(id){
    console.log(id,eventShiftList,"id")
   let shiftData1= !isEmpty(shiftData) && find(shiftData, x => x.event_shift_id ==id);
   console.log(shiftData1, "custm11");
   return shiftData1;
  }
  const handleChangeShift = (selectedOption) => {
    console.log(selectedOption, "sop");
    setEventShiftId(selectedOption.value);
    setEventShiftName(selectedOption.label);
   // props.setEditBooking({ key: "booking_type", value: selectedOption.label })
    props.setEditBooking({ key: "event_shift_id", value: selectedOption.value })
    props.setEditBookingCustomer({ key: "event_shift_id", value: selectedOption.value, key2:"event_shift", value2: getSigleEventShift(selectedOption.value) })
  };
  const eventShiftList =
    !isEmpty(shiftData) &&
    shiftData.map((x) => {
      let data = { value: x.event_shift_id, label: x.event_shift_name };
      return data;
    });
  console.log("getEventShihftList", eventShiftList);
  let bookingTypeData = [
    {
      booking_type_id: 1,
      booking_type_name: "FULL",
    },
    {
        booking_type_id: 2,
        booking_type_name: "PARTIAL",
    },
  ];
  

  const handleChangeBookingType = (selectedOption) => {
    console.log(selectedOption, "sop");
    setBookingTypeId(selectedOption.value);
    setBookingTypeName(selectedOption.label);
    props.setEditBooking({ key: "booking_type", value: selectedOption.label })
  };
  const bookingTypeList =
    !isEmpty(bookingTypeData) &&
    bookingTypeData.map((x) => {
      let data = { value: x.booking_type_id, label: x.booking_type_name };
      return data;
    });
  console.log("getEventShihftList", bookingTypeList);
  function getSiglemenu(id){
    console.log(id,props.menuList,"id")
   let menuData= !isEmpty(props.menuList) && find(props.menuList, x => x.menu_id ==id);
   console.log(menuData, "custm11");
   return menuData;
  }
  const handleChangeMenu = (selectedOption) => {
    console.log(selectedOption, "sop");
    setMenuId(selectedOption.value);
    setMenuName(selectedOption.label);
    let menuId= selectedOption.value;
    const getPrice =!isEmpty(props.menuList) && find(props.menuList, x => x.menu_id ==menuId);
    console.log("price",getPrice.menu_price);
   // props.bookingByIdList[0].booking_person=0;
   props.bookingByIdList[0].charging_price=getPrice.menu_price;
   //let newPrice=(props.bookingByIdList[0].bookingTotalPrice*props.bookingByIdList[0].booking_discount)/100;
   //props.bookingByIdList[0].booking_total_price=props.bookingByIdList[0].booking_total_price-newPrice;
   // setMenuPrice(getPrice.menu_price);
  //console.log(props.bookingByIdList[0].booking_total_price,"aaaaaaaaa")
    //setChargingPrice(getPrice.menu_price);
   // console.log(chargingPrice,"aaaaaaa")

    let p1=Number(props.bookingByIdList[0].charging_price)*Number(props.bookingByIdList[0].booking_person)
    let p2=Number(props.bookingByIdList[0].hall.hall_per_function_charges)+p1;
    let p=Number(sumBy(props.bookingByIdList[0].booking_data, "booking_data_price"))+p2+Number(addonsPrice1)
    //console.log(props.bookingAddons.item.booking_data_price,"mmmmmmmm")
    settotalPriceDummy(p);
    console.log(p,"pppp")
    let disc=0
    props.bookingByIdList[0].booking_discount_type=="Cash"?
    disc=props.bookingByIdList[0].booking_discount:
    disc=(p*Number(props.bookingByIdList[0].booking_discount)/100);
   
    let discPrice=(p-Number(disc))
    props.setEditBooking({ key: "booking_total_price", value: p })
    props.setEditBooking({ key: "booking_payable_price", value: discPrice })
    //setBookingTotalPrice(p);
    props.setEditBooking({ key: "menu_id", value: selectedOption.value })
    props.setEditBookingCustomer({ key: "menu_id", value: selectedOption.value, key2:"menu", value2: getSiglemenu(selectedOption.value) })
    let remPrice=props.bookingByIdList[0].booking_payable_price-sumBy(props.bookingByIdList[0].booking_installments,"installment_price");
    props.setEditBooking({key:"booking_remaining_price", value:remPrice})

  };
  const getMenuList =
    !isEmpty(props.menuList) &&
    props.menuList.map((x) => {
      let data = { value: x.menu_id, label: x.menu_name };
      return data;
    });
  console.log("getEventShihftList", getMenuList);
  
 
  function getSigleCustomer(id){
    console.log(id, props.customerMarqueeList,"id")
   let customer= !isEmpty(props.customerMarqueeList) && find(props.customerMarqueeList, x => x.customer_id ==id);
   console.log(customer, "custm11");
   return customer;
  }
  const getCustomerMarqueeList =
    !isEmpty(props.customerMarqueeList) &&
    props.customerMarqueeList.map((x) => {
      let data = {
        value: x.customer_id,
        label: `${x.customer_name}/${x.customer_cnic}`,
      };
      return data;
    });
  console.log("getEventShihftList", getCustomerMarqueeList);
  const handleChangeBooking = (selectedOption) =>
    {
       console.log(selectedOption, "sop")
        setBookingId(selectedOption.value);
       // setBookingName(selectedOption.label);
        
    }
    const handleChangeBookingById = (selectedOption) =>
    {
       console.log(selectedOption, "sop")
        setBookingId(selectedOption.value);
        setBookingName(selectedOption.label);
       setBookingShow(1)
        props.getBookingById(selectedOption.value)
        console.log(props.bookingByIdList,"11223344")
        
    }
   // const findHall=!isEmpty(props.hallList) && find(props.hallList, x => x.hall_id ==props.);
    
    //const getBookingById =!isEmpty(props.getBookingById) && find(props.getBookingById, x => x.booking_id ==bookingId);
    /*const getBookingByIdList =
    !isEmpty(props.bookingByIdList) &&
    props.bookingByIdList.map((x) => {
      let data = { value: x.booking_id, label: x.booking_name };
      return data;
    });*/
   // const getBookingList = !isEmpty(props.bookingList) && (props.bookingList.map((x) => { let data = { value: x.booking_id, label: x.booking_name }; return data;   })) ;
    //console.log('getBookiList', getBookingList, props.bookingList);
  
  useEffect(() => {
    //props.getExpense();
    // props.getMasterControl(1);
    // props.getBooking();
    // props.getEvent(1);
    // props.getSubEvent(1);
    // props.getHall(1);
    // props.getMenu(1);
    // props.getCustomerMarquee();
    props.getBookingStatus()
    setBookingShow(0)
    //settotalPriceDummy(props.bookingByIdList[0].booking_total_price)
   // props.getBookingById(18);

  }, []);
  return (
    <>
      {props.isFetchingBookingByIdData  ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
          <Row>
                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Select booking:</b></Form.Label>
                                            <Select
                                            isDisabled={isEmpty(props.bookingListWithStatus)}
                                            value={{label:bookingName}}
                                                placeholder="Select booking..."
                                                onChange={handleChangeBookingById}
                                                options={getBookingList}
                                            />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* {    console.log(props.bookingByIdList,"oooooooooooo5555"),
                                   console.log(props.bookingByIdList,"oooooooooooo"),
                                   console.log(props.bookingAddons,"oooooooooooo12")} */}
                                {bookingshow==1&&(
                            
                                  <div>            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Customer:</b>
                  </Form.Label>
                  <Select
                    isDisabled={true}
                    placeholder="Select Customer..."
                    value={{ label: isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].customer_table.customer_name }}
                    onChange={handleChangeCustomer}
                    options={getCustomerMarqueeList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Hall:</b>
                  </Form.Label>
                  <Select
                    isDisabled={true}
                 //   value={pro}
                    value={{ label: isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].hall.hall_name }}
                    placeholder="Select Hall..."
                    onChange={handleChangeHall}

                    options={getHallList}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Sitting Person:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={isEmpty(props.bookingByIdList)?"":props.bookingByIdList[0].hall.hall_sitting_capacity}
                    disabled={true}
                    placeholder="menu price:...."
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Per function Price:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].hall.hall_per_function_charges}
                    disabled={true}
                    placeholder="menu price:...."
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Per Head Price:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].hall.hall_per_person_charges}
                    disabled={true}
                    placeholder="menu price:...."
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Event:</b>
                  </Form.Label>
                  <Select
                    isDisabled={true}
                    placeholder="Select Event..."
                    value={{label:isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].event.event_name}}
                    onChange={handleChangeEvent}
                    options={getEventList}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select SubEvent:</b>
                  </Form.Label>
                  <Select
                    isDisabled={true}
                    value={{label:isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].sub_event.sub_event_name}}
                    placeholder="Select SubEvent..."
                    onChange={handleChangeSubEvent}
                    options={getSubEventsList}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Event Shift:</b>
                  </Form.Label>
                  <Select
                    isDisabled={true}
                    placeholder="Select Event Shift..."
                    value={{ label: isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].event_shift.event_shift_name}}
                    onChange={handleChangeShift}
                    options={eventShiftList}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select booking type:</b>
                  </Form.Label>
                  <Select
                    isDisabled={true}
                    placeholder="Select booking type..."
                    value={{ label: isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].booking_type}}
                    onChange={handleChangeBookingType}
                    options={bookingTypeList}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Menu:</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.bookingByIdList)}
                    value={{ label: isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].menu.menu_name}}
                    placeholder="Select Manu..."
                    onChange={handleChangeMenu}
                    options={getMenuList}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Menu Price:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].menu.menu_price}
                    disabled={true}
                    placeholder="menu price:...."
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Per Head Price:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                   // value={isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].menu.m}
                    disabled={true}
                    placeholder="Per Head price:...."
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Charging Price:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={isEmpty(props.bookingByIdList) ? "": props.bookingByIdList[0].charging_price}
                    placeholder="Charging price:...."
                    onChange={handleChargePrice
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Booking person:</b>
                  </Form.Label>
                  <Form.Control
                    value={isEmpty(props.bookingByIdList)? "": props.bookingByIdList[0].booking_person}
                    type="number"
                    placeholder="Booking person:...."
                    onChange={(e) => {
                    Number(e.target.value) <= Number(props.bookingByIdList[0].hall.hall_sitting_capacity) && props.setEditBooking({ key: "booking_person", value: e.target.value }) //SetBookingPerson(e.target.value)
                    let p=Number(e.target.value)*Number(props.bookingByIdList[0].charging_price);
                    
                    let p2=p+props.bookingByIdList[0].hall.hall_per_function_charges;
                    let p1=p2+Number(sumBy(props.bookingByIdList[0].booking_data,"booking_data_price"))+Number(addonsPrice1);
                    let disc=0
                    props.bookingByIdList[0].booking_discount_type=="Cash"?disc=props.bookingByIdList[0].booking_discount:
                    disc=(p1*Number(props.bookingByIdList[0].booking_discount)/100);
                    let discPrice=(p1-Number(disc));

                    console.log(p,p2,p1,disc,discPrice,"ppppppppp")
                    props.setEditBooking({ key: "booking_total_price", value: p1 })
                    props.setEditBooking({ key: "booking_payable_price", value: discPrice })
                    settotalPriceDummy(p1);
                    let remPrice=props.bookingByIdList[0].booking_payable_price-sumBy(props.bookingByIdList[0].booking_installments,"installment_price");
                    props.setEditBooking({key:"booking_remaining_price", value:remPrice})
                    }}
                   // max={Number(props.bookingByIdList[0].hall.hall_sitting_capacity)}
                    min={1}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Booking per person price:</b>
                  </Form.Label>
                  <Form.Control
                    value={isEmpty(props.bookingByIdList)? "": props.bookingByIdList[0].booking_price_per_person}
                    type="number"
                    placeholder="Booking per person price:...."
                    onChange={(e) => //setBookingPerPersonPrice(e.target.value)
                    props.bookingByIdList[0].booking_price_per_person
                  }
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Booking Total price:</b>
                  </Form.Label>
                  <Form.Control
                    value={isEmpty(props.bookingByIdList)? "": props.bookingByIdList[0].booking_total_price}
                    type="number"
                    disabled
                    placeholder="Booking total price:...."
                    // onChange={(e) => props.setEditBooking({key:"booking_total_price",value: props.bookingByIdList[0].booking_total_price})
                      //setBookingTotalPrice(e.target.value)
                    // }
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Booking Discount:</b>
                  </Form.Label>
                  <Form.Control
                    value={isEmpty(props.bookingByIdList)? "": props.bookingByIdList[0].booking_discount}
                    type="number"
                    disabled
                    placeholder="Booking Discount:...."
                   // disabled={totalPriceDummy==0}
                    onChange={(e) =>{ props.setEditBooking({key:"booking_discount",value:e.target.value})
                    console.log(totalPriceDummy,addonsPrice1)
                    console.log(props.totalPriceWithoutdisc,"last")
                    if(totalPriceDummy==0){
                    let discountRate=0
                    props.bookingByIdList[0].booking_discount_type=="Cash"?discountRate=props.bookingByIdList[0].booking_discount:
                    discountRate=  (props.totalPriceWithoutdisc*e.target.value)/100;
                    
                    console.log(discountRate,"aaaaaaaaaaaaa")
                    let finalPrice=Number(props.totalPriceWithoutdisc)-discountRate;
                    props.setEditBooking({key:"booking_total_price",value:props.totalPriceWithoutdisc})
                    props.setEditBooking({key:"booking_payable_price",value:finalPrice})
                    let remPrice=props.bookingByIdList[0].booking_payable_price-sumBy(props.bookingByIdList[0].booking_installments,"installment_price");
                    props.setEditBooking({key:"booking_remaining_price", value:remPrice})
                    settotalPriceDummy(props.totalPriceWithoutdisc);
                    }
                    else{
                    console.log(totalPriceDummy,"aaaaaaa")
                   
                    let discountRate=0
                    props.bookingByIdList[0].booking_discount_type=="Cash"?discountRate=props.bookingByIdList[0].booking_discount:
                    discountRate=  (props.totalPriceDummy*e.target.value)/100;

                    console.log(discountRate,"aaaaaaaaaaaaa")
                    let finalPrice=Number(totalPriceDummy)-discountRate;
                    props.setEditBooking({key:"booking_total_price",value:totalPriceDummy})
                    props.setEditBooking({key:"booking_payable_price",value:finalPrice})
                    let remPrice=props.bookingByIdList[0].booking_payable_price-sumBy(props.bookingByIdList[0].booking_installments,"installment_price");
                    props.setEditBooking({key:"booking_remaining_price", value:remPrice})
                    }}}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Discount Type:</b>
                  </Form.Label>
                  <Form.Control
                    value={isEmpty(props.bookingByIdList)? "": props.bookingByIdList[0].booking_discount_type}
                    type="text"
                    disabled
                    placeholder="Discount Type:...."
                    // onChange={(e) => props.setEditBooking({key:"booking_total_price",value: props.bookingByIdList[0].booking_total_price})
                      //setBookingTotalPrice(e.target.value)
                    // }
                  />
                </Form.Group>
              </Col>
            <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Booking Payable price:</b>
                  </Form.Label>
                  <Form.Control
                    value={isEmpty(props.bookingByIdList)? "": props.bookingByIdList[0].booking_payable_price}
                    type="number"
                    disabled
                    placeholder="Booking total price:...."
                    // onChange={(e) => props.setEditBooking({key:"booking_total_price",value: props.bookingByIdList[0].booking_total_price})
                      //setBookingTotalPrice(e.target.value)
                    // }
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Paid Amount:</b>
                  </Form.Label>
                  <Form.Control
                    // value={isEmpty(props.bookingByIdList)? "": props.bookingByIdList[0].booking_payable_price-props.bookingByIdList[0].booking_remaining_price}
                    value={isEmpty(props.bookingByIdList)? "": sumBy(props.bookingByIdList[0].booking_installments,"installment_price")}
                    type="number"
                    disabled
                    placeholder="Paid Amount:...."
                     max={!isEmpty(props.bookingByIdList)&&props.bookingByIdList[0].booking_total_price}
                    onChange={(e) => {
                      let p=Number(props.bookingByIdList[0].booking_total_price)-(e.target.value);
                      props.setEditBooking({key:"booking_remaining_price",value:p})
                      props.setEditBooking({key:"booking_advance",value:e.target.value})
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Booking Remaining price:</b>
                  </Form.Label>
                  <Form.Control
                    value={isEmpty(props.bookingByIdList)? "": props.bookingByIdList[0].booking_remaining_price}
                    type="number"
                    disabled
                    placeholder="Booking Remaining price:...."
                    onChange={(e) => setBookingRemainingPrice(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Booking Name :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Booking Name...."
                    value={isEmpty(props.bookingByIdList)? "": props.bookingByIdList[0].booking_name }
                  //  onChange={(e) => setBookingName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
           { props.bookingAddons && props.bookingAddons.map((item, i) => {
              console.log(item, "Sssss")
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="6" lg="6" md="12" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select AddOns*</b>
                        </Form.Label>
                        <Select
                          style={{ zIndex: 1 }}
                          value={{ label: isEmpty(props.bookingByIdList) ? "": item.master_control_item_name }}
                          placeholder="Select Items..."
                          name="master_control_item_id"
                           isDisabled
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={inventory}
                          //  isOptionDisabled={(option) => !isEmpty(find(inputField, x => x.master_control_item_id == option.value))}
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Price*</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Price...."
                           disabled
                          name="booking_data_price"
                          value={item.booking_data_price}
                           onChange={(e) => handleChange(e, i, "intpricec")}
                          onBlur={(e) => handleChange(e, i, "intpriceb")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                  
                 </FormGroup>
                </React.Fragment>
              );
            })}
             <Row>
                      <Col xl="12" lg="12" md="12" sm="12" xs="12">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Enter Booking Description:</b>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Booking Description....."
                            value={isEmpty(props.bookingByIdList)? "": props.bookingByIdList[0].booking_desc}
                            onChange={(e) =>
                              setBookingDescription(e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
              <Row>
              <Col xl="6" lg="6" md="6" sm="12" xs="12" >
              <Button
                className="sendButton"
                onClick={() => {
                  updateBooking();
                  setSubmit(true);
                }}
              >
                Save
              </Button>
              </Col>
              {/* <Col lg="6" sm="12" className="text-center">
                                    <Button className="sendButtonVoucher" onClick={addPayment}>Post</Button>
                                </Col> */}
              <Col xl="6" lg="6" md="6" sm="12" xs="12">
              <Button
                className="sendButton"
                style={{color:'white',backgroundColor:'black',width:'130px'}}
                disabled={!isEmpty(props.bookingByIdList)&&props.bookingByIdList[0].booking_remaining_price!=0?true:false}
                onClick={() => {
                  completeBooking(bookingId);
                }}
              >
                Complete Booking
              </Button>
              </Col>
              </Row>
              </div>
)}
          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBooking: (data, id, handleVisible, handleDangerVisible) =>
      dispatch(updateBooking(data, id, handleVisible, handleDangerVisible)),
    getExpense: (id) => dispatch(getExpense(id)),
    getMasterControl: (id) => dispatch(getMasterControl(id)),
    getBookingStatus: () => dispatch(getBookingStatus()),
    
    getEvent: (id) => dispatch(getEvent(id)),
    getSubEvent: (id) => dispatch(getSubEvent(id)),
    getHall: (id) => dispatch(getHall(id)),
    editAddons: (data) => dispatch(editAddons(data)),
    setEditBooking:(data)=>dispatch(setEditBooking(data)),
    setEditBookingCustomer:(data)=>dispatch(setEditBookingCustomer(data)),
    getMenu: (id) => dispatch(getMenu(id)),
    getCustomerMarquee: () => dispatch(getCustomerMarquee()),
    // UpdateHRExpense: (newData, oldData) => dispatch(UpdateHRExpense(newData, oldData)),
    deleteExpense: (menu_id) => dispatch(deleteExpense(menu_id)),
    getBookingById:(id) => dispatch(getBookingById(id)),
    completeBooking:(id)=>dispatch(completeBooking(id))
  };
};
const mapStateToProps = (state, ownProps) => ({
  departmentList: state.MarqueeReducer.departmentList,
  isFetchingExpenseData: state.MarqueeReducer.isFetchingExpenseData,
  isAddingExpenseData: state.MarqueeReducer.isAddingExpenseData,
  expenseList: state.MarqueeReducer.expenseList,
  masterControlsList: state.MarqueeReducer.masterControlsList,
  bookingListWithStatus: state.MarqueeReducer.bookingListWithStatus,
  isFetchingBookingDataWithStatus: state.MarqueeReducer.isFetchingBookingDataWithStatus,
  eventList: state.MarqueeReducer.eventList,
  subEventsList: state.MarqueeReducer.subEventsList,
  hallList: state.MarqueeReducer.hallList,
  menuList: state.MarqueeReducer.menuList,
  customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
  // bookingByIdList: state.MarqueeReducer.bookingByIdList,
  bookingByIdList: state.MarqueeReducer.bookingByIdList,
  
  isFetchingBookingByIdDataForEdit:state.MarqueeReducer.isFetchingBookingByIdDataForEdit,
  isFetchingBookingByIdData:state.MarqueeReducer.isFetchingBookingByIdData,
 // data:state.MarqueeReducer.data,
 bookingAddons:state.MarqueeReducer.bookingAddons,
 totalPriceWithoutdisc:state.MarqueeReducer.totalPriceWithoutdisc,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBookingMarquee);
