import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, } from "react-bootstrap";
import "./add-details.css";
import { addWareHouseName, getWarehouseData, deleteWareHouse, updateWareHouse, resetReducer, } from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";


const AddWarehouse = (props) => {
  const [warehouse, setWarehouse] = useState();
  const [warehouseKey, setWarehouseKey] = useState();

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "WareHouse Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadWareHouse() {
    props.addWareHouseName(
      {
        wareHouseName: warehouse,
        whKey: warehouseKey,
        userId: window.localStorage.getItem("userId"),
      },
      handleVisible,
      handleDangerVisible
    );
    document.getElementById("warehouseName").value = "";
  }
  const wareHouseData = props.warehouseList;

  useEffect(() => { }, []);
  return (
    <>
      {props.isFetchingwareHouseList || props.isAddingWareHouse ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingWareHouse ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Enter Warehouse Name  <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="warehouseName"
                        placeholder="Warehouse Name...."
                        onMouseLeave={(e) => setWarehouse(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {isEmpty(warehouse) ? (
                    <Button
                      disabled
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "black",
                        border: "1px solid black",
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button className="sendButton" onClick={uploadWareHouse}>
                      Save
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addWareHouseName: (data, handleVisible, handleDangerVisible) => dispatch(addWareHouseName(data, handleVisible, handleDangerVisible)),
    resetState: () => dispatch(resetReducer()),
  };
};

const mapStateToProps = (state, ownProps) => ({
  warehouseList: state.AddDataReducer.warehouseList,
  dataAddedWarehouse: state.AddDataReducer.dataAddedWarehouse,
  dataNotAddedWarehouse: state.AddDataReducer.dataNotAddedWarehouse,
  isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
  isAddingWareHouse: state.AddDataReducer.isAddingWareHouse,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddWarehouse);
