import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import "./admin-details.css";
import { connect } from "react-redux";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import AdminDemandModel from "./AdminDemandModel";
import MaterialTable from 'material-table';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import Select from 'react-select'
import { getAdminDemand, updateAdminDemand, updateAdminDemand1, updateDemand, deleteDemand, deleteDemandMaterial, saveId } from "./adminDetails.action";

const AdminDemandDetails = (props) => {
    const demandDetails = props.adminDemandList;
   /* const [state, setState] = React.useState({
        columns: [
            { title: 'Demand Number', field: 'demandId' },
            { title: ' Manual Demand #', field: 'demandName' },
            { title: 'Production Order #', field: 'orderNumber' },
            { title: 'Status', field: 'status' },
            { title: 'Demand Description', field: 'demandDescription' },
            { title: 'Created Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
        ],
        columnsData: [
            { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.sub_category.subcateKey + "-" + rowData.inventory.invKey, editable: 'never' },
            { title: 'Inventory Name', field: 'productName', editable: 'never' },
            { title: 'Quantity', field: 'productQuantity'}
            //,editComponent: props =>
           // <input name="he"/>}
            // <Select
            //   value={{ value: props.value, label: 'name' }}
            //   onChange={e => props.onChange(e.value)}
            //   name='fieldname'
            //   autoFocus
            //   textFieldProps={{
            //     label: "fieldname" ,
            //     InputLabelProps: {
            //       shrink: true,
            //     },
            //     variant: 'outlined',
            //   }}
            //   options={[{id:1, label : 'name'}].map((item) => ({
            //     value: item.id,
            //     label: item.name,
            //   }))}
            //  /> },
        ],
    });*/
    
    useEffect(() => {
        props.getAdminDemand();
    }, []);
    return (
        <>
            {props.isFetchingadminDemandList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Demand Details"
                            columns={[
                                { title: 'Demand Number', field: 'demandId' },
                                { title: ' Manual Demand #', field: 'demandName' },
                                { title: 'Production Order #', field: 'orderNumber' },
                                { title: 'Status', field: 'status' },
                                { title: 'Demand Description', field: 'demandDescription' },
                                { title: 'Created Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
                            ]}
                            data={demandDetails.reverse()}
                            defaultExpanded={true}
                            detailPanel={rowData => {
                                
                                return (
                                    <MaterialTable
                                    defaultExpanded={true}
                                        // actions={[
                                        //     (x) => {
                                        //         return rowData.status == "Approve"
                                        //             ? { icon: DeleteOutlineIcon, disabled: true }
                                        //             : { icon: DeleteOutlineIcon, disabled: false, onClick: (event, rowData) => { props.deleteDemandMaterial(rowData) } }
                                        //     },

                                        //     (x) => {
                                        //         return rowData.status == "Approve"
                                        //             ? { icon: EditIcon, disabled: true }
                                        //             : { icon: EditIcon, disabled: false, onClick: (event, rowData) => { props.updateDemand(rowData) } }
                                        //     },
                                        // ]}
                                        title="Product Details"
                                        columns={[
                                            { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.sub_category.subcateKey + "-" + rowData.inventory.invKey, editable: 'never' },
                                            { title: 'Inventory Name', field: 'productName', editable: 'never' },
                                            { title: 'Quantity', field: 'productQuantity'}
                                            //,editComponent: prop
                                        ]}
                                        data={rowData.demand_quantities}

                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: true,
                                            sorting: true,
                                            paging: true,
                                            pageSize: 200,       // make initial page size
                                            emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                            pageSizeOptions: [50, 100, 150, 200],
                                            //defaultExpanded: true,
                                            defaultExpanded:true,
                                            showDetailPanel: true,
                                            
                                            headerStyle: {
                                                position: 'sticky', top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}

                                                editable={{
                                                    isEditable : x => rowData.status == "Approve" ? false : true,
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    {
                                                        props.updateDemand(newData, oldData)
                                                       
                                                    }
                                                }, 600);
                                            }),
                                            isDeletable : x => rowData.status == "Approve" ? false : true,
                                            onRowDelete: (data) =>
                                            new Promise((resolve) => {
                                                console.log(data, "ooooooooooooo")
                                                setTimeout(() => {
                                                    resolve();
                                                    props.deleteDemandMaterial(data);
                                                }, 600);
                                            }),
                                    }}

                                    />
                                )
                                
                            }}
                            actions={[
                                (rowData) => {
                                    return rowData.status !== "pending"
                                        ? { icon: DoneIcon, disabled: true }
                                        : { icon: DoneIcon, disabled: false, onClick: (event, rowData) => { props.updateAdminDemand(rowData) } }
                                },
                                (rowData) => {
                                    return rowData.status !== "pending"
                                        ? { icon: ClearIcon, disabled: true }
                                        : { icon: ClearIcon, disabled: false, onClick: (event, rowData) => { props.updateAdminDemand1(rowData) } }
                                },
                                (rowData) => {
                                    return rowData.status == "Approve"
                                        ? { icon: DeleteOutlineIcon, disabled: true }
                                        : { icon: DeleteOutlineIcon, disabled: false, onClick: (event, rowData) => { props.deleteDemand(rowData.demandId) } }
                                },
                            ]}
                            // editable={{
                            //     onRowDelete: (oldData) =>
                            //         new Promise((resolve) => {
                            //             setTimeout(() => {
                            //                 resolve();
                            //                 props.deleteDemand(oldData.demandId);
                            //             }, 600);
                            //         }),
                            // }}
                            defaultExpanded={true}
                            
                            options={{
                                actionsColumnIndex: -1,
                                // toolbar: false,
                                filtering: true,
                                exportButton: true,
                                paging: true,
                                pageSize: 200,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200],
                                defaultExpanded: true,
                                showDetailPanel: true,
                                
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            onRowClick={(event, rowData, togglePanel) => (togglePanel(), props.saveId(rowData.demandId))}

                        />
                    </div>
                </Container>
            )}

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAdminDemand: () => dispatch(getAdminDemand()),
        updateAdminDemand: (data) => dispatch(updateAdminDemand(data)),
        updateAdminDemand1: (data) => dispatch(updateAdminDemand1(data)),
        updateDemand: (newData, oldData) => dispatch(updateDemand(newData, oldData)),
        deleteDemand: (demandId) => dispatch(deleteDemand(demandId)),
        deleteDemandMaterial: (data) => dispatch(deleteDemandMaterial(data)),
        saveId: (data) => dispatch(saveId(data))
    };
};
const mapStateToProps = (state, ownProps) => ({
    adminDemandList: state.adminDemandDetailReducer.adminDemandList,
    isFetchingadminDemandList: state.adminDemandDetailReducer.isFetchingadminDemandList
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminDemandDetails);