// reducers.js
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import accountReducer from "../views/user_views/accounts/account.reducers";
import adminAccountReducer from "../views/admin_views/accounts/account.reducers";
//import addLedgerReducer from "../views/user_views/forms/Ledger/level.reducer";
import usersReducer from "../views/admin_views/forms/users/users.reducer";
import LegderReducer from "../views/admin_views/forms/ledger/ledger.reducers";
import AddDataReducer from "../views/user_views/forms/AddDetails/AddDetails.reducers";
import VoucherReducer from "../views/user_views/forms/Vouchers/Voucher.reducers";
import accountsLevelReducer from "../views/user_views/forms/accountsLevels/accountsLevel.reducer";
import adminDemandDetailReducer from "../views/admin_views/AdminDetails/adminDetails.reducer";
// import productionReducer from "../views/user_views/forms/production/production.reducers";
import AccountLegderReducer from "../views/admin_views/accounts/ledger/Ledger.reducer";
import HrReducer from "../views/user_views/forms/HRDetails/AddDetails/HRDetails.reducers";
import MarqueeReducer from "../views/user_views/forms/MarqueeDetails/AddDetails/MarqueeDetails.reducers";

// import AddArticleReducer from "../views/user_views/forms/production/AddArticleFolder/AddArticles.reducers";

// import AddArticleMaterialReducer from "../views/user_views/forms/production/AddArticleMaterialFolder/AddArticleMaterial.reducers";
// import AddLabourReducer from "../views/user_views/forms/production/AddLabourFolder/AddLabour.reducers";
// import COnversionCostReducer from "../views/user_views/forms/production/ConversionCostFolder/Conversion.reducers";
// import OrderCreationReducer from "../views/user_views/forms/production/OrderCreationFolder/OrderCreation.reducer";
// import AddAdminCostReducer from "../views/user_views/forms/production/AdminCost/AddAdminCost.reducers";
// import AddIssuanceReducer from "../views/user_views/forms/production/IssuanceFolder/Issuance.reducers";

import TransactitonSummaryReducer from "../views/user_views/forms/accountsTransactionSummary/TransactionSummaryReducer.reducers";
import AddAgingReducer from "../views/user_views/forms/accountsAging/Aging.reducers";
// import productionDetailReducer from "../views/user_views/forms/production/ProductionDetails/ProductionDetail.reducer";
// import userReducerAdmin from "../views/admin_views/accounts/account.reducers";
import userReducer from "../views/user_views/accounts/account.reducers";
import userReducerAdmin from "../views/admin_views/accounts/account.reducers";
import profitLoss from "../views/user_views/accounts/ProfitLoss/ProfitLoss.reducer";
import permissionReducer from "../views/admin_views/forms/Permissions/UserPermissions.reducer";
import SalaryReducer from "../views/user_views/forms/HRDetails/salary/salary.reducers";
import GenerateSalaryReducer from "../views/user_views/forms/HRDetails/SalaryManagment/generateSalary.reducer";
import InvoiceReducer from "../views/user_views/forms/AddDetails/Invoice/InvoiceDetails.reducers"
import OrderReducer from "../views/user_views/forms/OrderDetail/Order.reducers"
import QuotationReducer from "../views/user_views/forms/AddDetails/Quotation/QuotationReducer";


// rootReducer.js
// Use ES6 object literal shorthand syntax to define the object shape
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    accountReducer,
    adminAccountReducer,
    userReducerAdmin,
    userReducer,
    //addLedgerReducer,
    GenerateSalaryReducer,
    usersReducer,
    LegderReducer,
    AddDataReducer,
    VoucherReducer,
    accountsLevelReducer,
    adminDemandDetailReducer,
    // productionReducer,
    AccountLegderReducer,
    HrReducer,
    MarqueeReducer,
    // AddArticleReducer,
    // AddArticleMaterialReducer,
    // AddLabourReducer,
    // COnversionCostReducer,
    // OrderCreationReducer,
    // AddAdminCostReducer
    // AddAdminCostReducer,
    // AddIssuanceReducer,
    TransactitonSummaryReducer,
    AddAgingReducer,
    // productionDetailReducer,
    profitLoss,
    permissionReducer,
    SalaryReducer,
    InvoiceReducer,
    OrderReducer,
    QuotationReducer,
  });
