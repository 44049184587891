import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import { getOrder, updateOrderStatus, updateOrderAPI } from "./Order.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect, useRef } from "react";
import { isEmpty, find, map } from "lodash";
import Modal from "react-bootstrap/Modal";
import jsPDF from "jspdf";
import logo from "../../../../Images/logo.png";
import TaskIcon from '@mui/icons-material/Task';


const ListOrder = (props) => {
    const OrderData = isEmpty(props.listOrder) ? [] : props.listOrder;
    const [addStatusModal1, setAddStatusModal1] = useState(false);
    const [trackingId, setTrackingId] = useState("");
    const [courierCompany, setCourierCompany] = useState("");
    const [orderId, setOrderId] = useState("");


    const handleShowAddStatus1 = (id) => {
        setAddStatusModal1(true);
        setOrderId(id);
    };
    const handleCloseAddStatus1 = () => {
        setOrderId("");
        setAddStatusModal1(false);
    };
    function uploadShipmentDetails(orderId) {
        props.updateOrderStatus({ trackingId: trackingId, courierCompany: courierCompany, orderId: orderId });
        setAddStatusModal1(false);
        setOrderId("");
        setTrackingId("");
        setCourierCompany("");
    }

    useEffect(() => {
        props.getOrder();
    }, []);

    return (
        <>
            <Container fluid>
                {props.isFetchingOrder ? (
                    <div className="loader-div">
                        <Button variant="info" disabled>
                            <Spinner
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="info"
                            />
                            Fetching Order Data
                        </Button>
                    </div>
                ) : (
                    <MaterialTable
                        style={{ zIndex: 0 }}
                        title="Running Orders"
                        columns={[
                            { title: 'Order ID', field: 'orderId', editable: 'never' },
                            { title: 'Customer Code', field: `customer_table.customer_cnic`, editable: 'never' },
                            { title: 'Order Status', field: 'orderStatus', editable: 'never' },
                            { title: 'Manual Order Number', field: 'orderNumber' },
                            { title: 'Order Description', field: 'orderDesc' },
                            { title: 'Order Date', field: 'orderDate', editable: 'never' },
                            { title: 'Order Delivery Date', field: 'orderDeliveryDate', editable: 'never' },
                            { title: 'Order Duration', field: 'dayDifference', editable: 'never' },
                            { title: 'Order Amount', field: 'orderAmount' },
                            { title: 'Cash Received', field: 'cashReceived', },
                            { title: 'Pending Amount', field: 'pendingAmount' },
                            { title: 'Currency', field: 'currency_symbol' },
                            { title: 'Tracking Id', field: 'trackingId' },
                            { title: 'Courier Company', field: 'courierCompany' },
                        ]}
                        data={OrderData}
                        detailPanel={(rowData) => {
                            return (
                                <MaterialTable
                                    // title="Expense Items"
                                    columns={[
                                        { title: 'Expense Description', field: 'expense_form_desc' },
                                        { title: 'Expense Quantity', field: 'expense_form_quantity' },
                                        { title: 'Expense Price', field: 'expense_form_data_price' },
                                    ]}
                                    data={rowData.expense_form_data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        toolbar: false,
                                        //   exportButton: true,
                                        sorting: false,
                                        paging: false,
                                        pageSize: 200, // make initial page size
                                        emptyRowsWhenPaging: false, // To avoid of having empty rows
                                        pageSizeOptions: [50, 100, 150, 200],
                                        headerStyle: {
                                            position: "sticky",
                                            top: 0,
                                            color: "#00BBBB",
                                            fontWeight: "550",
                                            onRowAdd: "none",
                                        },
                                    }}
                                // editable={{
                                //     onRowUpdate: (newData, oldData) =>
                                //         new Promise((resolve) => {
                                //             setTimeout(() => {
                                //                 resolve();
                                //                 {
                                //                     console.log('newData', newData),
                                //                     props.UpdateHRMenu(newData, oldData)
                                //                 }
                                //             }, 600);
                                //         }),
                                // }}
                                />
                            )
                        }}
                        options={{
                            actionsColumnIndex: -1,
                            filtering: true,
                            exportButton: true,
                            paging: true,
                            pageSize: 50,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [50, 100, 150, 200],
                            headerStyle: {
                                position: 'sticky', top: 0,
                                color: '#00BBBB',
                                fontWeight: '550',
                                onRowAdd: 'none',
                            },
                            exportPdf: (columns, data) => {
                                const doc = new jsPDF();
                                //render:rowData=>rowData.hall_name
                                const columnTitles = [
                                    { title: 'Order ID', field: 'orderId', render: rowData => rowData.orderId },
                                    { title: 'Order Name', field: 'orderType ', render: rowData => rowData.orderType },
                                    { title: 'Manual Order Number', field: 'orderNumber', render: rowData => rowData.orderNumber },
                                    { title: 'Order Description', field: 'orderDesc', render: rowData => rowData.orderDesc },
                                    { title: 'Order Date', field: 'orderDate', render: rowData => rowData.orderDate },
                                    { title: 'Order Delivery Date', field: 'orderDeliveryDate', render: rowData => rowData.orderDeliveryDate },
                                    { title: 'Order Amount', field: 'orderAmount', render: rowData => rowData.orderAmount },
                                    { title: 'Cash Received', field: 'cashReceived', render: rowData => rowData.cashReceived },
                                    { title: 'Pending Amount', field: 'pendingAmount', render: rowData => rowData.pendingAmount },
                                ]
                                    .map(columnDef => columnDef.title);
                                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                                const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                                const pdfData = data.map((rowData) => [rowData.orderId, rowData.orderType, rowData.orderNumber, rowData.orderDesc, rowData.orderDate, rowData.orderAmount, rowData.cashReceived, rowData.pendingAmount]);
                                let content = {
                                    startY: 50,
                                    startX: 5,
                                    head: [columnTitles],
                                    body: pdfData
                                };
                                doc.addImage(logo, 'PNG', 10, 1, 50, 50);
                                doc.setFontSize(20);
                                doc.setFont(undefined, 'bold')
                                doc.text(companyName, 50, 22, 0, 0);
                                doc.setFontSize(13);
                                doc.setFont('TimesNewRoman', 'Normal')
                                doc.text(companyAddress, 50, 29, 0, 0);
                                doc.text(companyNtn, 50, 36, 0, 0);
                                doc.autoTable(content);
                                doc.save(`Order.pdf`);
                            },
                        }}
                        actions={[
                            (rowData) => {
                                return rowData.orderStatus !== "Pending"
                                    ? {
                                        icon: TaskIcon,
                                        disabled: true,
                                    }
                                    : {
                                        icon: TaskIcon,
                                        disabled: false,
                                        onClick: (event, rowData) => {
                                            // props.updateOrderStatus(rowData, updateTableQuery);
                                            handleShowAddStatus1(rowData.orderId);
                                        },
                                    };
                            },
                        ]}

                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        {
                                            props.updateOrderAPI(newData, oldData)
                                        }
                                    }, 600);
                                }),
                            // onRowDelete: (oldData) =>
                            //     new Promise((resolve) => {
                            //         setTimeout(() => {
                            //             resolve();
                            //             props.deleteCustomer(oldData.customer_id);
                            //             console.log(oldData, 'old')
                            //         }, 600);
                            //     }),
                        }}
                    />
                )}
                <Modal
                    show={addStatusModal1}
                    onHide={handleCloseAddStatus1}
                    animation={false}
                    style={{}}
                    size="md"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Order Shipment Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label>
                                        <b>Tracking ID </b>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Tracking Id"
                                        value={trackingId}
                                        onChange={(e) => { setTrackingId(e.target.value); }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label>
                                        <b>Courier Company </b>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Courier Company"
                                        value={courierCompany}
                                        onChange={(e) => { setCourierCompany(e.target.value); }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv" style={{ marginTop: "20px" }}>
                            <Button
                                disabled={isEmpty(trackingId) || isEmpty(courierCompany)}
                                style={{
                                    marginLeft: "10%",
                                    color: "black",
                                    width: "20%",
                                    backgroundColor: "#C5CBCF",
                                    border: "1px solid white",
                                }}
                                onClick={() => uploadShipmentDetails(orderId)}
                            >
                                Save
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </Container>
        </>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrder: () => dispatch(getOrder()),
        updateOrderStatus: (data) => dispatch(updateOrderStatus(data)),
        // deleteCustomer: (id) => dispatch(deleteCustomer(id)),
        updateOrderAPI: (newData, oldData) => dispatch(updateOrderAPI(newData, oldData)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingOrder: state.OrderReducer.isFetchingOrder,
    listOrder: state.OrderReducer.listOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListOrder);
