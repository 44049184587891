import { filter, toLower, isNull } from "lodash";

const initialState = {
    listOrder: [],
    isFetchingOrder: false,
    listOrderWithCustomerId: [],
    isFetchingOrderWithCustomerId: false,
    orderListWithOrderId: [],
    isFetchingOrderListWithOrderId: false,
    AddlistOrder: [],
    isAddingOrderData: false,
    searchOrderList: [],
    AddOrderPayment: [],
    isAddingOrderPayment: false,
};

const OrderReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_ORDER":
            return { ...state, isAddingOrderData: true };
        case "SUCCESS_ADD_ORDER":
            return {
                ...state,
                AddlistOrder: action.payload,
                isAddingOrderData: false,
            };
        case "ERROR_ADD_ORDER":
            return { ...state, isAddingOrderData: false };


        case "REQUEST_GET_ORDER":
            return {
                ...state,
                isFetchingOrder: true
            };
        case "SUCCESS_GET_ORDER":
            return {
                ...state,
                listOrder: action.payload.data,
                searchOrderList: action.payload.data,
                isFetchingOrder: false,
            };
        case "ERROR_GET_ORDER":
            return { ...state };



        case "REQUEST_GET_ORDER_WITH_CUSTOMER_ID":
            return {
                ...state,
                isFetchingOrderWithCustomerId: true
            };
        case "SUCCESS_GET_ORDER_WITH_CUSTOMER_ID":
            return {
                ...state,
                listOrderWithCustomerId: action.payload.data,
                isFetchingOrderWithCustomerId: false,
            };
        case "ERROR_GET_ORDER_WITH_CUSTOMER_ID":
            return { ...state };



        case "REQUEST_GET_ORDER_WITH_ORDERID":
            return {
                ...state,
                isFetchingOrderListWithOrderId: true
            };
        case "SUCCESS_GET_ORDER_WITH_ORDERID":
            return {
                ...state,
                orderListWithOrderId: action.payload.data,
                isFetchingOrderListWithOrderId: false,
            };
        case "ERROR_GET_ORDER_WITH_ORDERID":
            return { ...state };


        case "FILTER_ORDER":
            return {
                ...state,
                listOrder: action.payload == "" ? state.searchOrderList : filter(state.searchOrderList, x => toLower(x.orderNumber) == action.payload || toLower(x.customer_table.customer_name) == action.payload || toLower(x.customer_table.customer_cnic) == action.payload)
            };


        case "REQUEST_ADD_ORDER_PAYMENT":
            return { ...state, isAddingOrderPayment: true };
        case "SUCCESS_ADD_ORDER_PAYMENT":
            return {
                ...state,
                AddOrderPayment: action.payload,
                isAddingOrderPayment: false,
                orderListWithOrderId: []
            };
        case "ERROR_ADD_ORDER_PAYMENT":
            return { ...state, isAddingOrderPayment: false };

        default:
            return state;

    }
};
export default OrderReducer;
