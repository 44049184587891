import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Swal from "sweetalert";
import { addInvoice, getCustomer } from "./InvoiceDetails.actions";
import { getQuotationApi } from "../Quotation/QuotationAction";
import moment from "moment";


const AddInvoice = (props) => {
  const [customer, setCustomer] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);



  function uploadDepartment() {
    props.addInvoice({
      quotationId: customer,
    },
      handleVisible,
      handleDangerVisible
    );
  }

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  const filterQuotation = filter(props.quoteData, { status: 'Pending' });
  const getCustomer = filterQuotation.map((x) => {
    let data = { label: `${x.id} / ${x.date}`, value: x.id };
    return data;
  });
  const setCustomerInfo = (selectedOption) => {
    setCustomer(selectedOption.value);
  };

  useEffect(() => {
    props.getQuotationApi();
  }, []);
  return (
    <>
      {props.isFetchingQuoteData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col xl="6" lg="6" md="6" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label> <b>Select Quotation *</b></Form.Label>
                    <Select
                      placeholder="Select Quotation"
                      onChange={setCustomerInfo}
                      options={getCustomer}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="sendDiv">
                {customer == "" ?
                  <Button className="sendButton" disabled style={{ backgroundColor: 'black' }}> Genrate Invoice </Button> :
                  <Button className="sendButton" onClick={uploadDepartment} > Genrate Invoice </Button>}
              </div>

            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addInvoice: (data, handleVisible, handleDangerVisible) => dispatch(addInvoice(data, handleVisible, handleDangerVisible)),
    getQuotationApi: () => dispatch(getQuotationApi()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingQuoteData: state.QuotationReducer.isFetchingQuoteData,
  quoteData: state.QuotationReducer.quoteData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoice);
